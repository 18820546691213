import React, { Component } from 'react';

// needs the shortlist
import { connect } from 'react-redux';
import { addMotivationsToShortlist,toggleMotivation } from  '../../redux/actions/ShortlistAction';

import Song  from '../Song';

class FormMotivation extends Component {

    constructor(props) {
      super(props);
      this.motivs = [];
    }

    componentDidMount(){

        /*
         * Code to open the first motivation as default.. when user has closed it should not do it anymore
         */

        // Check if we have to auto open the first song
        let {list} = this.props;

        // Bail out when no list
        if(!list.length)
            return;

        // We need 0 visibles
        var countVisible = 0;

        list.forEach((item, index)=>{
            countVisible +=  (item._motivationVisible === true ? 1 : 0);
        });

        // AND the first visible flag should be undefined
        if( countVisible === 0 && ( list[0]._motivationVisible === undefined) ) {
            // Now toggle one
            this.props.toggleMotivation(0);
        }

        this.tryToSetFocus();
    }

    tryToSetFocus(){

        /**
         * This one tries to set the focus to the first element
         */

        let {list} = this.props;
        // We need 0 visibles
        var firstVisibleIndex = null;

        list.forEach((item, index)=>{
            if(firstVisibleIndex === null && item._motivationVisible === true){
                firstVisibleIndex = index;
            }
        });

        if(firstVisibleIndex !== null){
            this.motivs[firstVisibleIndex].focus();
        }

    }

    // Toggle motivation text area open and closed ( and send to redux )
    onSongClick (index){
        this.props.toggleMotivation(index);
    }

    /**
     * Here, on a blur we save the data to the redux store
     */
    saveInReduxStore(index,e){
        this.props.addMotivationsToShortlist(e.target.value, index);
    }

    getSongs(values){

        let {list, page} = this.props;
        let {translate} = page;
        let songs = list.map ( (song,index) => {
            return (
                <li key={index}>
                    {/* The Song */}
                    <Song index={index} song={song} currStep={this.props.currStep} onSongClick={this.onSongClick.bind(this)} type="edit" />

                    {/* The Motivation */}
                    <div className="motivation-container" style={{display: (song._motivationVisible ? 'block' : 'none') }}>
                        <label className="visually-hidden" htmlFor={"motivation" + [index]}>{translate('Waarom heb je voor dit nummer gekozen?')}</label>
                        <textarea
                            id={"motivation" + [index]}
                            ref={(ref) => this.motivs[index] = ref }
                            placeholder={translate('Waarom heb je voor dit nummer gekozen?')}
                            className="height--200 border border--radius-standard color--mine-shaft font-size--sm padding--sm--top padding--md--right padding--md--left"
                            defaultValue={song._motivation}
                            onBlur={this.saveInReduxStore.bind(this, index)}
                        />
                    </div>
                </li>
            )
        });

        return (
            <ul className="list--motivation liststyle--none">
                {songs}
            </ul>
        );

    }

    render() {
        return (
            this.getSongs()
        );
    }

}

const mapStateToProps = (state) => {

    return {
        list: state.shortlist.list
    }

}


export default connect( mapStateToProps, {addMotivationsToShortlist, toggleMotivation} )(FormMotivation);
