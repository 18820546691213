import React, { Component } from "react";
import { connect } from "react-redux";
import NotificationSystem from "react-notification-system";
import Parser from "html-react-parser";
import jsonp from "jsonp";

import {
  setSessionHash,
  setShareHash,
  setStepsSaved,
  setStepEnabled,
} from "../redux/actions/StemtoolAction";
import { setShortlist } from "../redux/actions/ShortlistAction";
import { saveForm, saveEnquete } from "../redux/actions/FormAction";
import Header from "../components/Header";

class PageVerifyFromUrl extends Component {
  _notificationSystem = null;

  translatables = null;

  constructor(props) {
    super(props);

    let { stemtool } = this.props;
    let formstates = stemtool.formstates;
    let steps = stemtool.properties.steps;
    let { sessionHash, verificationHash } = this.props.match.params;

    this.translatables = JSON.parse(stemtool.properties.translatables);

    // Validate votelist with backend...
    jsonp(
      process.env.REACT_APP_BACKEND_PROTOCOL_AND_URL +
        "api/verify/" +
        stemtool.properties.slug +
        "?session_hash=" +
        sessionHash +
        "&verification_hash=" +
        verificationHash,
      {
        name: "verifySubmission",
      },
      (err, data) => {
        if (err) {
          this._prompt(
            "<h4>" +
              this.translate("Er is iets mis gegaan") +
              ":</h4><br />" +
              err.message
          );
          return;
        }

        if (data.msg === "closed") {
          // Find the step that has the closed type
          let closeStep = null;
          steps.forEach((step) => {
            if (step.type === "closed") closeStep = step;
          });
          this.props.history.push(
            "/" + stemtool.properties.slug + "/" + closeStep.stepNo
          );
          return;
        } else if (data.msg !== "ok") {
          this._prompt(
            "<h4>" +
              this.translate("Er is iets mis gegaan") +
              ":</h4><br />" +
              data.msg
          );

          return;
        }

        // Re-hydrate localStorage
        this.props.setSessionHash(data.state.session_hash);
        this.props.setShareHash(data.state.share_hash);
        this.props.setShortlist(data.state.shortlist);
        this.props.saveForm(data.state.form_naw, {}, {});
        if (data.state.form_enquete) {
          this.props.saveEnquete(data.state.form_enquete, {}, {});
        }

        // Find verify-step
        let verifyStepNo = 0;
        let verifyStepIndex = 0;
        steps.forEach((step, i) => {
          if (step.type === "verify") {
            verifyStepNo = step.stepNo;
            verifyStepIndex = step.stepIndex;
          }
        });

        formstates.forEach((formstate, i) => {
          if (i <= verifyStepIndex) {
            // Mark step as saved & enabled
            this.props.setStepEnabled(steps[i]);
            this.props.setStepsSaved(steps[i]);
          }

          if (i === verifyStepIndex) {
            // Enable next step
            this.props.setStepEnabled(steps[i + 1]);
          }
        });

        // And jump to that page
        props.history.push(
          "/" + stemtool.properties.slug + "/" + (verifyStepNo + 1)
        );
      }
    );
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  render() {
    let { stemtool } = this.props;

    return (
      <div className="container">
        <NotificationSystem ref="notificationSystem" />

        {/* Top panel */}
        <header className="header-container col col--100 clear">
          <Header stemtool={stemtool.properties} />
        </header>
        <main className="flex col--100">
          <div className="col col--60 clear">
            <div className="panel--gray">
              <div className="padding">
                <div className="col col--100 clear">
                  <div className="font-size--md color--white font-weight--bold heading-sidebar">
                    <h1>Je stem wordt gevalideerd...</h1>
                  </div>
                </div>
                <div className="col col--100 clear margin--top">
                  <div className="font-size--sm margin--bottom color--lightgray">
                    Even geduld, we zijn je stem aan het valideren.
                  </div>
                </div>
                <div className="clear" />
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }

  translate = (key) => {
    if (!this.translatables) {
      return null;
    }
    if (this.translatables[key]) {
      return this.translatables[key];
    } else {
      return key;
    }
  };

  _prompt = (message, level = "warning", position = "bc", duration = 0) => {
    this._notificationSystem.addNotification({
      message: Parser(message),
      level: level,
      position: position,
      autoDismiss: duration,
    });
  };
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(
  mapStateToProps,
  {
    setSessionHash,
    setShareHash,
    setShortlist,
    saveForm,
    saveEnquete,
    setStepEnabled,
    setStepsSaved,
  }
)(PageVerifyFromUrl);
