import React, { Component } from 'react';
import { connect } from 'react-redux';
import Sticky from 'react-sticky-el';
import { Link } from 'react-router-dom';


import { saveData, setStepsSaved, setStepEnabled, resetApplication,setElasticSearchSeed, getFlow,setResetFlag  } from '../redux/actions/StemtoolAction';
import { setValid,setAllEnqueteTouced,setIsSubmitting,unsetIsSubmitting,clearGoogleRecaptcha, saveForm } from '../redux/actions/FormAction';



/***************************************************************************
 * Here should be all the navigation tricks
 * !! Steps are set VALID or INVALID by the components that needs validation
 * !! Steps are set SAVED by this component
 **************************************************************************/

class Navigation extends Component {

    stepValid = false;

    // Just for development
    clear(e){
        let {slug} = this.props;
        e.preventDefault();
        this.props.history.push('/' + slug + '/clear' );
    }

    onClickNext(e){

        let {steps, slug, properties, translate} = this.props;
        let {currStep, nextStep} = steps;

        if (currStep.type === 'form') {

            // When we're form and we click the next butt we want to have all form fields
            // set to touched so the constraints will show (in red)
            let {values, errors} = this.props.formdata;

            let touched = {};
            for (var i in values) {
                touched[i] = true;
            }
            this.props.saveForm(values, errors, touched);
        }


        // this.stepValid is set in render (so when the button is blue this one should be true)
        if(!this.stepValid){
            e.preventDefault();
            switch(currStep.type){
                case 'enquete':
                    this.props.setAllEnqueteTouced();
                    break;
                case 'form':
                    break;
                default:
            }
            return;
        }

        // Is this the last step? If yes we will clean all redux and jump to first step
        // (means we clicked the navigation next step but on the last flow page!!)
        if(nextStep ? nextStep.nonflow : true){
            e.preventDefault();
            // And jump to first step with a clear as parameter
            // in pages/Page this will init a clear
            this.props.history.push('/' + slug + '/clear' );
            return;
        }

        if(this.props.issubmitting){
            return;
        }
        if(currStep.saveonnext){
            // Tell system we've started a submit
            this.props.setIsSubmitting();

            // !! have to wait for the callback to jump to the next !! //
            e.preventDefault();

            // Save (we just save ALL the user data)
            this.props.saveData( (msg) => {
                // callback
                if(msg === 'ok'){
                    // Tell redux to set saved all previous steps and this one
                    this.props.setStepsSaved(currStep);
                    // enable the next step
                    this.props.setStepEnabled(nextStep);
                    // tell we're done submitting
                    this.props.unsetIsSubmitting();
                    // Now we can programatically jump next
                    this.props.history.push('/' + slug + '/'+ nextStep.stepNo);
                } else if( msg === 'closed'){
                    // This one runs when the backend gives a closed
                    // Normally one should not end up here because one should
                    // be redircted to the 'closed' page already by the js clock

                    // Find the closed step
                    steps.all.forEach((step) => {
                        if(step.type === 'closed'){
                            // And prompt the message
                            this.props.page._prompt(
                                '<h4>' + properties.title + '</h4><br/>' +
                                step.content.title + '<br/><br/>' +
                                step.content.body + '<br/><br/>'
                            );
                        }
                    });
                    // tell were done submitting
                    this.props.unsetIsSubmitting();
                } else if( msg === 'recaptchaerror'){
                    this.props.page._prompt(
                        '<h4>'+ translate('We konden niet goed controleren of je geen robot bent') + '</h4><br/>' + translate('Probeer het alsjeblieft nog een keer.') + '<br/><br/>',
                        'warning',
                        'tc',
                        3
                    );
                    this.props.clearGoogleRecaptcha();
                    this.props.unsetIsSubmitting();
                    if (window.grecaptcha !== undefined) {
                        window.grecaptcha.reset();
                    }
                } else if( msg === 'fail' ){
                    this.props.page._prompt(
                        '<h4>' + translate('Er is iets mis gegaan') + '</h4><br/>' + translate('Probeer het alsjeblieft nog een keer.') + '<br/><br/>'
                    );
                    this.props.clearGoogleRecaptcha();
                    this.props.unsetIsSubmitting();
                    if (window.grecaptcha !== undefined) {
                        window.grecaptcha.reset();
                    }
                } else {
                    this.props.page._prompt( msg );
                    this.props.clearGoogleRecaptcha();
                    this.props.unsetIsSubmitting();
                    if (window.grecaptcha !== undefined) {
                        window.grecaptcha.reset();
                    }
                }
            } );

        } else {

            if(nextStep){
                this.props.setStepEnabled(nextStep);
            }
        }

    };

    onClickPrev(e){

        // Nothing to do so far!

    };

    render() {

        if(this.props.formstates.length === 0){
            return null;
        }

        // Some easy readin
        let {steps, slug, mobile, issubmitting, translate} = this.props;
        let {currStep, nextStep, prevStep} = steps;

        // If we have a prev step and it is saved we can not go back
        let prevStepIsSaved = prevStep ? this.props.formstates[prevStep.stepIndex].saved : false;
        // If we have a next step and its a nonflow step this step is the last step. No step at all also last step
        let isLastStep = nextStep ? nextStep.nonflow : true;

        // From redux, check if we should be able to go to the next page
        // Step must be valid
        // And if the the next page saves data there must be a recaptcha
        // !!! BUT NOT WHEN YOU'RE LOGGED IN (have a _token) !!!
        this.stepValid = currStep.skipable
            || (
                    currStep.saveonnext 
                    && this.props.formstates[currStep.stepIndex].valid 
                    && (this.props.recaptcha || this.props._token || this.props.session_hash)
                )
            || (!currStep.saveonnext && this.props.formstates[currStep.stepIndex].valid);

        // Calculate width of buttons
        if (mobile) {
            var width = 100;
        } else {
            width = (!prevStepIsSaved && prevStep && nextStep) ? 50 : 100;
        }

        return (
            <Sticky mode="bottom" positionRecheckInterval={1} stickyClassName="sticky sticky--navigation" >
                <div className={`navigation-block clear ${mobile ? 'mobile' : ''}`}>
                    {/*  OK only butt that when leads to step 1 */}
                    {isLastStep && currStep.content.nextbutton &&
                    <div className="navigation-btn--container">
                        <button
                              className={`text-transform--uppercase btn btn--left btn--next ${this.stepValid ? 'btn--blue' : 'btn--gray'}`}
                              onClick={this.onClickNext.bind(this)} {...(!this.stepValid && {'disabled': true})} aria-disabled={this.stepValid ? false : true}>
                            <div className="title--container">
                                <span className="button--title t--right">{currStep.content.nextbutton}</span>
                            </div>
                        </button>
                    </div>}

                    {!prevStepIsSaved && prevStep && currStep.content.previousbutton &&
                    <div className={`col col--${width} clear`}>
                       <div className="navigation-btn--container text-transform--uppercase">

                            <Link to={`/${slug}/${prevStep.stepNo}`}
                                  className="btn btn--left btn--previous btn--gray"
                                  onClick={this.onClickPrev.bind(this)}>
                             <span className="before"></span>
                             <div className="title--container">
                                <span className="button--title t--right">{currStep.content.previousbutton}</span>
                             </div>
                             <span className="after"></span>
                          </Link>
                       </div>
                    </div>}

                    {!isLastStep && nextStep && currStep.content.nextbutton &&
                    <div className={`col col--${width} clear`}>
                        <div className="navigation-btn--container text-transform--uppercase">

                            <Link to={`/${slug}/${nextStep.stepNo}`}
                                  className={`btn btn--left btn--next ${this.stepValid ? 'btn--blue' : 'btn--gray'} ${issubmitting ? 'btn--issubmitting' : ''}`}
                                  onClick={this.onClickNext.bind(this)} {...(!this.stepValid && {'disabled': true})} aria-disabled={this.stepValid ? false : true}>
                                <span className="before"></span>
                                <div className="title--container">
                                    <span className="button--step">{translate('Stap')} {nextStep.stepNo}</span>
                                    <span className="button--title t--right">{issubmitting ? translate('Even geduld...') : currStep.content.nextbutton}</span>

                                </div>
                                <span className="after">
                                    {issubmitting && <div className="spinner"></div>}
                                </span>
                            </Link>

                        </div>

                    </div>}

                </div>
                {false && document.location.hostname === 'localhost' &&
                <div className="navigation-btn--container text-transform--uppercase">
                    <span className="btn" onClick={this.clear.bind(this)}>Clear</span>
                </div>}

            </Sticky>
        );
    };

}


const mapStateToProps = (state) => {
    return {
        properties: state.stemtool.properties,
        formstates: state.stemtool.formstates,
        issubmitting: state.formdata.issubmitting,
        recaptcha: state.formdata.recaptcha,
        _token: state.stemtool._token,
        session_hash: state.stemtool.session_hash,
        formdata: state.formdata.naw,
        enquete: state.formdata.enquete
    }
}

export default connect(mapStateToProps, {
    saveData, 
    setStepsSaved, 
    setValid, 
    setStepEnabled, 
    resetApplication,
    setElasticSearchSeed, 
    getFlow,setResetFlag,
    setAllEnqueteTouced,
    setIsSubmitting,
    unsetIsSubmitting,
    clearGoogleRecaptcha,
    saveForm 
})(Navigation);
