import React, {Component} from 'react';
import ModalFreechoice from "./ModalFreechoice";
import connect from "react-redux/es/connect/connect";

var refToModalFreechoice = null;

class SongFreechoice extends Component {

    onOpenModalClick(){
        refToModalFreechoice.handleOpenModal();
    }

    /**
     * Need to have a ref to our modal to open it
     * @param ref
     */
    getModal(ref) {
        refToModalFreechoice = ref;
    }

    onSongClick(index){
        // Can do some here
    }

    /**
     * Fancy stuff
     *
     * Not used now
     *
     * @param search
     * @returns {{artist: *, title: *}}
     */
    guessArtistAndTitle(search){
        var t = search.split(' ');
        let artist = t[0];

        t.shift();
        let title = t.join(' ');

        return {artist: artist, title: title};

    }

    render() {

        let {currStep, page, mobile} = this.props;
        let {translate} = page;

        //let artTit = this.guessArtistAndTitle(search.value);

        if(currStep.freeChoiceStrategy === 'add_to_max'){
            return null;
        }

        let type = 'add';
        let selected = false;

        let image = '';
        if (currStep.freeChoiceImage) {
            image = currStep.freeChoiceImage;
        } else {
            image = process.env.REACT_APP_BACKEND_PROTOCOL_AND_URL + 'images/stemtool/default/100x100.png'
        }

        let index = -666;

        return (
            <div className="container--song" onClick={() => this.onSongClick(index)}>
                <div>
                    <div className="link">
                        <div
                            className={`flex--center ${ type === 'edit' ? 'evt--pointer' : '' } song color--gray87 ${ selected ? 'panel--blue panel--blue--h' : 'panel--gray87 panel--gray87--h'}`}>

                            <div className="song--image">
                                {(image) && <img
                                    className="song--image"
                                    src={image} alt=""/>}
                            </div>
                            <div>
                                <p className="song--artist font-size--sm"> {translate('Niet gevonden wat je zocht?')} </p>
                                <p className="song--title font-size--sm"> {translate('Voeg een vrije keuze toe')} </p>

                            </div>
                            <div className="song--action">

                                {(type === 'add' && !selected) ?
                                    <button className="btn btn--blank btn--h" onClick={this.onOpenModalClick.bind(this)}>
                                        <i className="icon icon__add--black"/>
                                    </button> : null}

                            </div>
                        </div>
                    </div>
                </div>
                <ModalFreechoice init={this.getModal} currStep={currStep} mobile={mobile} translate={translate} page={page}/>
            </div>)
    }

}
const mapStateToProps = (state) => {
    return {
        search: state.longlist.search
    }
}
export default connect(mapStateToProps, {})(SongFreechoice);
