import { getChannelBrandId } from './ChannelBrandId.js'

const Analytics = {
	count: (channel, slug, page) => {
		// Do Toppsin measurement
		Analytics.doTopspincount(channel, slug, page);
	},

    click: (type, name) => {
        Analytics.doTopspinclick(type, name);
    },

	doTopspincount: (channel, slug, page) => {
		var pageTrackers = {
			page: page,
			chapter_1: "stem",
            chapter_2: slug,
            environment: process.env.REACT_APP_TOPSPIN_ENV || "dev",
            platform: "site",
		};

		switch(channel) {
            case 'nporadio1':
                pageTrackers.brand_id = getChannelBrandId('nporadio1');
                pageTrackers.brand = "nporadio1";
            break;
            case 'nporadio2':
                pageTrackers.brand_id = getChannelBrandId('nporadio2');
                pageTrackers.brand = "nporadio2";
            break;
            case 'npo3fm':
                pageTrackers.brand_id = getChannelBrandId('npo3fm');
                pageTrackers.brand = "npo3fm";
            break;
            case 'npoklassiek':
                pageTrackers.brand_id = getChannelBrandId('nporadio4');
                pageTrackers.brand = "nporadio4";
            break;
            case 'nporadio5':
                pageTrackers.brand_id = getChannelBrandId('nporadio5');
                pageTrackers.brand = "nporadio5";
            break;
            case 'nposterrennl':
                pageTrackers.brand_id = getChannelBrandId('nporadio5');
                pageTrackers.brand = "nporadio5";
                pageTrackers.chapter_3 = pageTrackers.chapter_3;
                pageTrackers.chapter_2 = pageTrackers.chapter_2;
                pageTrackers.chapter_1 = "sterrennl";
            break;
            case 'npofunx':
                pageTrackers.brand_id = getChannelBrandId('npofunx');
                pageTrackers.brand = "npofunx";
            break;
            case 'songfestival':
                pageTrackers.brand_id = 96;
                pageTrackers.brand = "songfestival";
            break;

            default:
                pageTrackers.brand_id = 4;
                pageTrackers.brand = "npoportal";
            break;
        }

        window.pageTrackers = pageTrackers;

        if(typeof window.npotag === "undefined") {
            (function(document, tag) {
			    var scriptTag = document.createElement(tag),
			        firstScriptTag = document.getElementsByTagName(tag)[0];
			    scriptTag.src = 'https://npotag.npo-data.nl/prod/npo-tag.min.js';
                scriptTag.async = true;
			    firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);

                scriptTag.addEventListener("load", function () {
                    const tag = window.npotag.newTag(
                      {
                        brand: window.pageTrackers.brand,
                        brand_id: window.pageTrackers.brand_id,
                        platform: "site",
                        platform_version: "0.1.0",
                      },
                      [window.npotag.newATInternetPlugin(), window.npotag.newGovoltePlugin()]
                    );

                    const tracker = window.npotag.newPageTracker(
                      tag,
                      window.pageTrackers
                    );
                    tracker.pageView();

                    window.tag = tag;
                    window.tracker = tracker;
                  });
			}(document, 'script'));
        } else {
            if (window.tracker) {
                // we need to manually create a new pageTracker and track the pageView
                const tracker = window.npotag.newPageTracker(
                window.tag,
                window.pageTrackers
            );
                tracker.pageView();
            }
        }
	},

    doTopspinclick: (type, name) => {
        if (window.tracker) {
            window.tracker.click({
              click_name: name,
              click_type: type,
            });
          }
    }
};

export { Analytics as default };
