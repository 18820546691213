import React, { Component } from 'react';
import { connect } from 'react-redux';

import { toggleShowShortlits,toggleHighlight } from  '../redux/actions/StemtoolAction';

class MobileShortistHeader extends Component {

    componentWillUnmount(){
        // Remove the non-scrolling classname from the body when we do not have this component
        document.body.className = '';
    }

    toggle = () => {

        this.props.toggleShowShortlits();
        if(this.props.highlight){
            this.props.toggleHighlight();
        }
        // Cancel out scrolling on body when shortlist is visible

    }

    render() {

        if(this.props.formstates.length === 0){
            return null;
        }

        let {list, currStep, mobile_show_shortlist,translate } = this.props;



        var list_length = 0;
        var freechoice_length = 0;



        list.forEach( (item) => {

            if(item._id !== '0'){
                list_length++;
            } else {
                freechoice_length++;
            }
        });


        switch (currStep.freeChoiceStrategy) {
            case 'add_to_max':
                break;
            case 'include_in_max':
                list_length += freechoice_length;
                break;
            default:
        }


        // When we have too much records in our shortlist
        let tooMuchTooLittle = ( list_length > currStep.max );

        // Need to disable the scrolling of the body.. cuz thats the longlist
        // document.body.className = mobile_show_shortlist ? 'non-scrolling' : '';

        /**
         * The mobile vesrion has a toggle butt
         */
        return (
            true ? (<div className="container panel--gray shortlist--header" onClick={this.toggle}>
                <div className="font-size color--white shortlist--title flex flex--space-b flex--align--vert">
                    <p className="d--inl-block">

                        {list_length > 0 ? list_length + ' ': translate('Je Favorieten') }
                        {list_length === 1 ? translate('Favoriet') : ''}
                        {list_length > 1 ? translate('Favorieten') : ''}

                        <span className={`font-size--sm color--suvagray t--right shortlist--count ${tooMuchTooLittle ? 'color--red font-weight--bold' : 'color--suvagray'}`} > ({translate('min')} {currStep.min}, {translate('max')} {currStep.max}) </span>

                        { (currStep.freeChoiceStrategy === 'add_to_max' && (freechoice_length > 0 && !mobile_show_shortlist)) &&
                            (<span className={`font-size--sm ${freechoice_length > currStep.max_free_choice ? 'color--red' : '' }`}> (+{freechoice_length})</span>)
                        }

                    </p>
                    <i className={`d--inl-block icon icon__open ${mobile_show_shortlist ? 'open' : 'closed'}`}></i>
                </div>
            </div>)  : null
        )

    }
}

const mapStateToProps = (state) => {

    return {
        list: state.shortlist.list,
        mobile_show_shortlist: state.stemtool.mobile_show_shortlist,
        formstates: state.stemtool.formstates,
        highlight: state.stemtool.highlight,
    }

}


export default connect( mapStateToProps, {toggleShowShortlits,toggleHighlight} )(MobileShortistHeader);
