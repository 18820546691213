import Header from '../components/Header';
import React, { Component } from 'react';
import Parser from 'html-react-parser';
import Analytics from '../model/Analytics';

class PageClosed extends Component {

    constructor(props) {
        super(props);

        var {slug, online, offlineForNewSubmissions} = props.stemtool;

        // Redirect back to start when tool is NOT closed
        var now = new Date();

        var match = online.match(/^(\d+)-(\d+)-(\d+) (\d+):(\d+):(\d+)$/);
        var dateOnline = new Date(Date.UTC(match[1], match[2] - 1, match[3], match[4], match[5], match[6]));

        match = offlineForNewSubmissions.match(/^(\d+)-(\d+)-(\d+) (\d+):(\d+):(\d+)$/);
        var dateOfflineForNewSubmissions = new Date(Date.UTC(match[1], match[2] - 1, match[3], match[4], match[5], match[6]));

        let stemtoolIsActiveForNewSubmissions = (( now - dateOnline ) > 0 ) && ((dateOfflineForNewSubmissions - now) > 0) ? true : false;
        if (stemtoolIsActiveForNewSubmissions) {
            this.props.history.push('/' + slug + '/1');
            return;
        }

        // Analytics
        Analytics.count(props.stemtool.channel_slug, props.stemtool.slug, props.steps.currStep.type);
    }

    render() {

        let {stemtool,steps} = this.props;

        let {currStep} = steps;


        return (
                <div className="container">
                
                    <div className="col col--100 clear">
                        <Header stemtool={stemtool}/>
                    </div>
                                    
                    <div className="col col--100 padding panel--gray color--white font-weight--bold font-size--md">
                        <p>{currStep.content.title}</p>
                    </div>
                    
                    <div className="col col--100 padding no-padding-top panel--gray color--white font-weight--bold font-size--sm">                    
                        <p>{Parser(currStep.content.body ? currStep.content.body : '')}</p>                                
                        <br />                          
                    </div>
                </div>
           );
        }
    }

    export default PageClosed;
