import {store} from '../redux/store'

import { setSelected } from  '../redux/actions/LonglistAction';
import { addSongToShortlist } from  '../redux/actions/ShortlistAction';
import { toggleHighlight } from  '../redux/actions/StemtoolAction';


export default {

    /**
     *
     * @param index : index of the longlist
     * @param component : is the ReactComponent, the one we call this funct from
     * @param _source : is a longlist song
     * @param artist : when freechoice
     * @param title : when freechoice
     *
     * This code has become iffy..
     * This is because we can add a longlist item through the longlist itself (easy... by index)
     * Or form the SongsDetail <Component/>... there we hav no index ( we should have one there :( )
     * We move in a _soucre from the SongDetail component and figure out the index again
     *
     * Third option is a freechoice
     */
    addToShortlist: (index, component, _source, artist, title) => {

        let isFreechoice = index === null && _source === null ? true : false;


        let myStore = store.getState();
        let {list} = myStore.longlist;
        let shortlist = myStore.shortlist.list;
        let {currStep, translate} = component.props;

        var shortlist_count = 0;
        var freechoice_count = 0;
        shortlist.forEach((item) => {
            if (item._id !== '0') {
                shortlist_count++;
            } else {
                freechoice_count++;
            }
        });

        // Do error messages
        switch (currStep.freeChoiceStrategy) {
            case 'add_to_max':
                if(isFreechoice){
                    if (freechoice_count >= currStep.max_free_choice) {
                        // And prompt the message (method in pages/Page.js)
                        component.props.page._prompt(
                            translate('Let op: Je hebt nu meer vrije keuzes toegevoegd dan is toegestaan.') + '<br/>', 'warning', 'tc', 3
                        );
                    }
                } else {
                    if (shortlist_count >= currStep.max) {
                        // And prompt the message (method in pages/Page.js)
                        component.props.page._prompt(
                            translate('Let op: Je hebt nu meer keuzes toegevoegd dan is toegestaan.') + '<br/>', 'error', 'tc', 3
                        );
                    }
                }
                break;
            case 'include_in_max':
                if ((shortlist_count + freechoice_count) >= currStep.max) {
                    // And prompt the message (method in pages/Page.js)
                    component.props.page._prompt(
                        translate('Let op: Je hebt nu meer keuzes toegevoegd dan is toegestaan.') + '<br/>', 'error', 'tc', 3
                    );
                }

                if(isFreechoice){
                    if (freechoice_count >= currStep.max_free_choice) {
                        // And prompt the message (method in pages/Page.js)
                        component.props.page._prompt(
                            translate('Let op: Je hebt nu meer vrije keuzes toegevoegd dan is toegestaan.') + '<br/>', 'warning', 'tc', 3
                        );
                    }
                }
                break;
            default:
        }


        // Use the index or use a real _source or.. when freechoice make item
        if (index !== null) {
            // By index
            var song = list[index];
        } else {
            if (_source !== null) {
                // Real source
                song = _source;
            } else {
                // Freechoice: We're mimicking Elasticsearch data here..
                song = {
                    _id: '0',                 // !! is a string and 0 means FREECHOICE !!
                    _source: {
                        id: 0,                // !! is an int 0 means FREECHOICE !!
                        artist: artist,
                        title: title,
                        image: currStep.freeChoiceImage
                    }
                };
            }
        }

        // Is this song already in the shortlist?
        var isDuplicated = false;
        shortlist.forEach((item) => {
            if(
                song._source.artist.toLowerCase().trim() === item._source.artist.toLowerCase().trim()
                && song._source.title.toLowerCase().trim() === item._source.title.toLowerCase().trim()
            ) {
                isDuplicated = true;
                return false;
            }
        });

        if(isDuplicated) {
            component.props.page._prompt(
                translate('Je hebt dat nummer al gekozen'), 'error', 'tc', 3
            );
            return false;
        }

        // Pass a clone!! (You need the step here so the step can be set to valid)
        addSongToShortlist(Object.assign({}, song), currStep)(store.dispatch, store.getState);
        toggleHighlight()(store.dispatch, store.getState);

        // Have to find the index of the longlist when we have no index
        // (when index === null and source === null we're freechoice)
        if (index === null && _source !== null) {
            for (var i in list) {
                if (list[i]._id === _source._id)
                    break;
            }
            index = i;
        }

        if (index !== null) {
            setSelected(index)(store.dispatch, store.getState);
        }

    }
}
