import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-sticky-el';
import jsonp from 'jsonp';
import { captureException } from '@sentry/react';

import StepContent from '../components/StepContent';
import Header from '../components/Header';
import Song from '../components/Song';
import Player from "../components/Player";
import Analytics from '../model/Analytics';
import ModalShareToInstagram from '../components/ModalShareToInstagram';

var refToInstagramModal = null;


class PageShare extends Component {

    shortlist = null;
    name = null;

    _player = null; // ref for the player


    /**
     *
     * PageShare is different.. everybody can land here and the data is pulled from the backend..
     * can not use extend Page cuz that one is for flows so need to copy the translate module :(
     */
    translatables = null;
    translate = (key) => {
        if(this.translatables[key]){
            return this.translatables[key];
        } else {
            return key;
        }
    }


    constructor(props){
        super(props);

        /**
         * Get the share data from the backend (the PHP backend)
         */

        let {hash} = this.props.match.params; // !! from Route !!
        let {stemtool} = this.props;

        // Parse the JSON here cuz ES has trouble with keys with spaces in them
        this.translatables = JSON.parse(props.stemtool.translatables);

        jsonp( process.env.REACT_APP_BACKEND_PROTOCOL_AND_URL + 'api/form/' + stemtool.slug + '/' + hash,
        {
            name: 'shareData'
        }, (err, data) => {
            if(err) {
                captureException(err);
                return;
            }

            this.shortlist = data.shortlist;
            this.name = data.name;

            this.setState({ dataLoaded: true });

        });

        Analytics.count(props.stemtool.channel_slug, props.stemtool.slug, props.steps.currStep.type);
    }

    setInstagramModal(ref, className) {
        refToInstagramModal = ref;
    }

    toggleInstagram() {
        refToInstagramModal.handleOpenModal();
    }

    render() {
        // Wait for state change
        if(!this.state) return null;

        let {mobile, stemtool} = this.props;
        let {currStep} = this.props.steps;

        // Create the songs
        let songs = this.shortlist.map ( ( song, index ) => {
            return (<li key={index}><Song index={index} song={song} currStep={currStep} type="share" parent={this} /></li>)
        });

        return (
            <div className="container">

                <Player ref={ (player) => {this._player = player}} />

                <header className="header-container col col--100 clear">
                    <Header stemtool={stemtool}/>
                </header>

                {/******************** MOBILE VERSION *****************************/}

                {mobile && <div className='col col--100 mobile--container step--share clear'>

                    {/* Left panel */}
                    <div className="col col--100 clear panel--gray padding">
                        {/* Top.. config stuff */}
                        <StepContent currStep={currStep}/>

                        <div className={`col col--100 clear`}>
                            <div className="navigation-btn--container text-transform--uppercase">

                                <Link to={`/${stemtool.slug}`} className={`btn btn--left btn--next btn--blue`}>
                                    <span className="before"></span>
                                    <div className="title--container">
                                        <span className="button--title t--right">{this.translate('STEM NU!')}</span>
                                    </div>
                                    <span className="after"></span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* Main panel */}
                    <div className="col col--100 clear panel--white padding">

                        <h2 className={`margin--bottom font-weight--bold color--darkgray ' ${mobile ? 'font-size' : 'font-size--md'}`} >
                            {this.translate('Dit is de stemlijst van')} {this.name}
                        </h2>

                        <ul className="liststyle--none">
                            {songs}
                        </ul>
                    </div>
                </div>}

                {/******************** DESKTOP VERSION *****************************/}
                {!mobile && <main className='flex col--100 step--share'>

                    {/* Left panel */}
                    <aside className="col col--40 sidebar clear">
                        <div className='sticky--container panel--gray'>
                            <Sticky mode="top" positionRecheckInterval={1} >
                                <div className='padding'>
                                    {/* Top.. config stuff */}
                                    <StepContent currStep={currStep}/>

                                    <div className={`col col--60 clear`}>
                                        <div className="navigation-btn--container text-transform--uppercase">

                                            <Link to={`/${stemtool.slug}`} className={`btn btn--left btn--next btn--blue`}>
                                                <span className="before"></span>
                                                <div className="title--container">
                                                    <span className="button--title t--right">{this.translate('STEM NU!')}</span>
                                                </div>
                                                <span className="after"></span>
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                            </Sticky>
                        </div>
                    </aside>

                    {/* Main panel */}
                    <div className="col col--60 clear mainbar">
                        <div className='panel--white padding'>

                            <h2 className={`margin--bottom font-weight--bold color--darkgray ' ${mobile ? 'font-size' : 'font-size--md'}`} >
                                {this.translate('Dit is de stemlijst van')} {this.name}
                            </h2>

                            <ul className="liststyle--none list--share">
                                {songs}
                            </ul>
                        </div>
                    </div>
                </main>}

                { document.location.hash === '#share=instagram' ? <ModalShareToInstagram opened="1" init={this.setInstagramModal} stemtool={stemtool} shortlist={this.shortlist} mobile={mobile} translate={this.translate} currStep={currStep} /> : null }

            </div>
       );
    }
}

export default PageShare;
