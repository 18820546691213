export const FORMDATA_TO_REDUX              = 'FORM/FORMDATA_TO_REDUX';
export const FREECHOICE_TO_REDUX   = 'FORM/FREECHOICE_TO_REDUX';

export const TOGGLE_TOOLBAR_FREECHOICE  = 'FORM/TOGGLE_TOOLBAR_FREECHOICE';
export const TOGGLE_TOOLBAR_FILTER      = 'FORM/TOGGLE_TOOLBAR_FILTER';
export const INIT_ENQUETE               = 'FORM/INIT_ENQUETE';
export const ENQUETE_TO_REDUX          = 'FORM/ENQUETE_TO_REDUX';

export const SET_ALL_ENQUETE_TOUCHED    = 'FORM/SET_ALL_ENQUETE_TOUCHED';
export const SET_ALL_FORM_TOUCHED       = 'FORM/SET_ALL_FORM_TOUCHED';
export const SET_IS_SUBMITTING          = 'FORM/SET_IS_SUBMITTING';
export const UNSET_IS_SUBMITTING        = 'FORM/UNSET_IS_SUBMITTING';

export const SET_GOOGLE_RECAPTCHA       = 'FORM/SET_GOOGLE_RECAPTCHA';