import React, {Component} from 'react';
import {connect} from 'react-redux';

import {toggleShowShortlits} from '../redux/actions/StemtoolAction';

class ShortlistHeader extends Component {

    toggle = () => {

        this.props.toggleShowShortlits();
        // Cancel out scrolling on body when shortlist is visible

    }

    render() {

        let {translate} = this.props;



        if (this.props.formstates.length === 0) {
            return null;
        }

        let {list, currStep} = this.props;

        // // Count the non freechoice items
        // var list_length = 0;
        // list.forEach((item) => {
        //     if (item._id !== '0') list_length++;
        // });
        var list_length = 0;
        var freechoice_length = 0;
        var list_max_length = currStep.max;

        list.forEach( (item) => {

            if(item._id !== '0'){
                list_length++;
            } else {
                freechoice_length++;
            }
        });


        switch (currStep.freeChoiceStrategy) {
            case 'add_to_max':
                break;
            case 'include_in_max':
                list_length += freechoice_length;

                if (currStep.max_free_choice !== currStep.max) {
                    list_max_length = currStep.max - freechoice_length;
                }
                break;
            default:
        }

        // When we have too much records in our shortlist
        let tooMuchTooLittle = (list_length > currStep.max);

        /**
         * Desktop
         */
        return (
            <div className='shortlistheader margin--bottom flex flex--align--vert'>
                <div className="col col--60 clear">
                    <div className='font-size color--white shortlist--title'>
                        <p>
                            {list_length > 0 ? list_length + ' ': translate('Je Favorieten') }
                            {list_length === 1 ? translate('Favoriet') : ''}
                            {list_length > 1 ? translate('Favorieten') : ''}

                        </p>
                    </div>
                </div>

                <div className="col col--40 clear">
                    <div className='font-size--sm color--suvagray t--right shortlist--count'>
                        <p className={tooMuchTooLittle ? 'color--red font-weight--bold' : ''}>
                            { currStep.min > 0 ?
                                '(' + translate('min') + ' ' + (currStep.min > list_max_length ? list_max_length : currStep.min) + ', ' + translate('max') + ' ' + list_max_length + ')' :
                                '(' + translate('max') + ' ' + list_max_length + ')'
                            }
                        </p>
                    </div>
                </div>
            </div>
        )

    }
}

const mapStateToProps = (state) => {

    return {
        list: state.shortlist.list,
        formstates: state.stemtool.formstates
    }

}


export default connect(mapStateToProps, {toggleShowShortlits})(ShortlistHeader);
