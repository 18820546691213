import React, {Component} from 'react';
import axios from 'axios';
import connect from "react-redux/es/connect/connect";
import {setToken} from "../redux/actions/StemtoolAction";
import { captureException } from '@sentry/react';

class PageLogin extends Component {

    constructor(props) {

        super(props);

        this.state = {
            login: '',
            pass: '',
            fail: false
        }
    }

    handleLoginChange(e) {
        this.setState({login: e.target.value});
    }

    handlePasswordChange(e) {
        this.setState({pass: e.target.value});
    }

    handleSubmit(e) {
        // I use AJAX here so
        e.preventDefault();

        var data = {
            'login': this.state.login,
            'pass': this.state.pass
        }

        axios.request(process.env.REACT_APP_BACKEND_PROTOCOL_AND_URL + 'api/login', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data : JSON.stringify(data)
        }).then(response => {
            if(response.data.msg === 'OK'){
                this.setState({fail: false})
                this.props.setToken(response.data._token);
            } else {
                this.setState({fail: true})
            }
        }).catch(error => {
            captureException(error);
        });


    }

    render() {

        return (
            <div style={{paddingTop: "100px"}}>
                <form onSubmit={this.handleSubmit.bind(this)}>

                    <table>
                        <tbody>
                        {!this.props._token &&
                        <tr>
                            <td>
                                E-mailadres: <input type="email" name="login" value={this.state.login}
                                             onChange={this.handleLoginChange.bind(this)}/>
                                Wachtwoord: <input type="password" name="pass" value={this.state.pass}
                                             onChange={this.handlePasswordChange.bind(this)}/>
                            </td>
                        </tr>
                        }
                        <tr>
                            <td style={{height: '1em'}}>
                            </td>
                        </tr>
                        {!this.props._token &&
                        <tr>
                            <td>
                                <input type="submit" value="Log in" />
                            </td>
                        </tr>
                        }
                        {this.state.fail &&
                        <tr>
                            <td style={{ color: 'red'}}>
                                Failed to login.
                            </td>
                        </tr>
                        }
                        {this.props._token &&
                        <tr>
                            <td style={{ color: 'green'}}>
                                Je bent ingelogd! <br /><br />
                                Klik <a href={'/' + this.props.slug}>hier</a> om te stemmen zonder Captcha
                            </td>
                        </tr>

                        }
                        </tbody>
                    </table>
                </form>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        _token: state.stemtool._token,
        slug:   state.stemtool.properties.slug

    }
}


export default connect( mapStateToProps, {  setToken })(PageLogin);