import React from 'react';
import { connect } from 'react-redux';
import Sticky from 'react-sticky-el';
import StepContent from '../components/StepContent';
import Header from '../components/Header';
import Shortlist from '../components/Shortlist';
import Navigation from '../components/Navigation';
import Social from '../components/Social';

import Page from './Page';


class PageThankyou extends Page {


    render() {

    let {mobile, stemtool, steps, history} = this.props;
    let {currStep} = steps;

    return (
        <div className="container">

            {/* Top panel */}
            <header className="header-container col col--100 clear">
                <Header stemtool={stemtool}/>
            </header>

            {/******************** MOBILE VERSION *****************************/}
            {mobile && <div className='col col--100 mobile--container clear'>

                <div className="padding--mobile-block--bottom">
                    {/* Info panel */}
                    <div className="padding--md margin--bottom panel--gray">
                        <StepContent currStep={currStep} translate={this.translate} />
                        <Social stemtool={stemtool} currStep={currStep} mobile={mobile} translate={this.translate} />
                    </div>
                    <div className="col col--100 clear">
                        <div className="panel--white margin--left margin--right">
                            <div className="col col--100 clear">
                                <div className='thankyou--header margin--bottom'>
                                    <h2 className={`font-weight--bold color--darkgray ${mobile ? 'font-size' : 'font-size--md'}`} >
                                        {this.translate('Jouw stemlijst')}
                                    </h2>
                                    <p>{this.translate('Stemlijst van')} {this.props.form.name} </p>
                                </div>
                                <Shortlist stemtool={stemtool} steps={this.props.steps} currStep={currStep} buttType="thankyou" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container navigation-container--mobile">
                    <Navigation slug={stemtool.slug} steps={steps} history={history} mobile={mobile} translate={this.translate} />
                </div>
            </div>}

            {/******************** DESKTOP VERSION *****************************/}
            {!mobile && <main className='flex col--100'>
                {/* Info panel */}
                <aside className="col col--40 sidebar clear">
                    <div className='sticky--container panel--gray'>
                        <Sticky mode="top" positionRecheckInterval={1} >
                            <div className='padding'>
                                <StepContent currStep={currStep} translate={this.translate} />
                                <Social stemtool={stemtool} currStep={currStep} mobile={mobile} translate={this.translate} />
                            </div>
                        </Sticky>
                    </div>
                </aside>

                <div className="col col--60 clear">
                    <div className='panel--white padding'>
                        <div className='flex col--100 thankyou--header'>
                            <div className='margin--bottom'>
                                <h2 className={`font-weight--bold color--darkgray ${mobile ? 'font-size' : 'font-size--md'}`} >
                                    {this.translate('Jouw stemlijst')}
                                </h2>
                                <p>{this.translate('Stemlijst van')} {this.props.form.name} </p>
                            </div>
                        </div>
                        {/* Shortlist */}
                        <Shortlist  stemtool={stemtool} steps={this.props.steps} currStep={currStep} buttType="thankyou" />

                        <Navigation slug={stemtool.slug} steps={steps} history={history} mobile={mobile} translate={this.translate} />
                    </div>
                </div>
            </main>}
        </div>);

    }
}

const mapStateToProps = (state) => {
    return {
        form: state.formdata.naw.values,
        share_hash: state.stemtool.share_hash
    }
}

export default connect(mapStateToProps)(PageThankyou);
