import React from 'react'
import Parser from 'html-react-parser';

const StepContent = ({currStep, translate}) => {

return (

        <div>

            { currStep.type !== 'share' ? // No stepno when the step type is a share
                <div><div className="col col-max--40 clear">
                    <div className='step flex--step margin--right margin--bottom color--darkgray font-size--sm panel--white border--radius-sm'>
                        <p>{translate('Stap')} {currStep.stepNo}</p>
                    </div>
                </div>
                <div className="col col-max--60 clear">
                    <div className='font-size--md color--white font-weight--bold heading-sidebar'>
                        <h1>{currStep.content.title}</h1>
                    </div>
                </div></div> :
                <div className="col col--100 clear">
                    <div className='font-size--md color--white font-weight--bold heading-sidebar'>
                        <h1>{currStep.content.title}</h1>
                    </div>
                </div>
            }

            <div className="col col--100 clear margin--top">
                <div className="font-size--sm margin--bottom color--lightgray">
                    {Parser( currStep.content.body ? currStep.content.body : '')}
                </div>
            </div>
            <div className='clear' />
        </div>
        )
}
export default StepContent
