const channels = {
    nporadio1: {
        prod: 631154,
        dev: 631153,
    },
    nporadio2: {
        prod: 631152,
        dev: 631151,
    },
    npo3fm: {
        prod: 631150,
        dev: 631149,
    },
    nporadio4: {
        prod: 631148,
        dev: 631147,
    },
    nporadio5: {
        prod: 631146,
        dev: 631145,
    },
    npofunx: {
        prod: 631144,
        dev: 631143,
    }
}

export const getChannelBrandId = (channel) => {
    const isProd = process.env.REACT_APP_TOPSPIN_ENV === 'prod';
    return isProd ? channels[channel].prod : channels[channel].dev;
}