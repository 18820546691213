import React, { Component } from 'react';
import { connect } from 'react-redux';

import { saveFormFreechoice,toggleFreeChoice } from  '../../redux/actions/FormAction';
import { addSongToShortlist } from  '../../redux/actions/ShortlistAction';
import { toggleHighlight } from  '../../redux/actions/StemtoolAction';
import { setSearchValue } from '../../redux/actions/LonglistAction';
import ModelShortlist from '../../model/ModelShortlist';

class FormFreechoice extends Component {

    componentDidMount(){
        // Copy the current redux data into the state (initial load, you need this!)
        this.setState({formdata: this.props.formdata});
    }

    myValidator = (values) => {

        let errors = {};

        if (!values.artist) {
            errors.artist = 'Vul dit veld in a.u.b.';
        }

        if (!values.title) {
            errors.title = 'Vul dit veld in a.u.b.';
        }

        return errors;
    }


    onChange(e){

        let {values, errors, touched} = this.state.formdata;
        // Need the value here
        values[e.target.name] = e.target.value;
        // Set touched
        touched[e.target.name] = true;
        // Set the errors
        errors = this.myValidator(values);

        this.setState({formdata: {values, errors, touched}});

        this.storeFormdataInRedux(); // Redux stuff

    }

    // So when we reload we continue where we were
    storeFormdataInRedux(immediately = false){

        let {values, errors, touched} = this.state.formdata;

        // Only want after some time so clear the timer when we're here again when its
        if(this.timer){
            clearTimeout(this.timer);
        }
        // We wait a bit before we send (or get cleared)
        this.timer = setTimeout(() => {
            this.props.saveFormFreechoice(values,errors,touched);
        }, immediately ? 0 : 1000);
    }

    cancel(){
        // We do not have a page element to show or hide when include_in_max
        if(this.props.currStep.freeChoiceButton === 'above_longlist'){
            this.props.toggleFreeChoice();
        }

        if(this.props.modal){
            this.props.modal.handleCloseModal();
        }

    }

    // Add the freechoice to the shortlist
    addToShortlist(){
        let {values,touched} = this.state.formdata;
        // prevent empty saves
        if(Object.keys(touched).length === 0) return;

        ModelShortlist.addToShortlist(null, this, null, values.artist, values.title);

        // Clear form and then clear redux and close the form
        this.setState ({ formdata:{ values: { title:'', artist:''}, errors:{}, touched:{}}},
            () => {
                this.storeFormdataInRedux(true);

                // Reset search
                this.props.setSearchValue('', this.props.currStep);
                
                // Hide freechoice form on submit when button is above_longlist
                if(this.props.currStep.freeChoiceButton === 'above_longlist') {
                    this.props.toggleFreeChoice();
                }

                if(this.props.modal){
                    this.props.modal.handleCloseModal();
                }
            }
        );
    }

    render() {

        if(!this.state) return null;
        let {values, errors, touched} = this.state.formdata;
        let {translate, currStep} = this.props;
        let {freeChoiceButton} = currStep;

        return (
            <div>
                <div className="clear"></div>
                <div className="form margin--top">
                    <div className="form-element">
                        <div className="input-group-from-md">
                            <label htmlFor="artist">{translate('Artiest')}</label>
                            <input  type="text"
                                    placeholder=""
                                    name="artist"
                                    id="artist"
                                    autoComplete="off"
                                    value={values.artist}
                                    onChange={this.onChange.bind(this)}
                                    onBlur={this.onChange.bind(this)}
                                    className="input border border--radius-sm font-size--sm" />
                        </div>
                        {touched.artist && errors.artist && <div className="error">{errors.artist}</div>}
                    </div>
                    <div className="form-element">
                        <div className="input-group-from-md">
                            <label htmlFor="title">{translate('Titel')}</label>
                            <input  type="text"
                                    placeholder=""
                                    id="title"
                                    name="title"
                                    autoComplete="off"
                                    value={values.title}
                                    onChange={this.onChange.bind(this)}
                                    onBlur={this.onChange.bind(this)}
                                    className="input border border--radius-sm font-size--sm" />
                        </div>
                        {touched.title && errors.title && <div className="error">{errors.title}</div>}
                    </div>

                    {/* Mobile has a popup or strategy include in max has one */}
                    { (this.props.mobile || freeChoiceButton === 'in_longlist') &&
                    (<div>
                        <button type="submit" className="btn btn--blue" onClick={this.addToShortlist.bind(this)} >
                            Voeg toe aan mijn lijst
                        </button>
                        <div className="btn" style={{'position':'absolute', 'top':'0', 'right':'0', 'left':'0', 'cursor':'initial', 'backgroundColor':'black', 'color':'white', 'lineHeight':'40px', 'textAlign':'center', 'fontSize':'16px'}} >
                            <button type="submit" onClick={this.cancel.bind(this)} style={{'position':'absolute', 'top':'0', 'left':'0', 'height':'40px', 'width':'40px', 'cursor':'pointer', 'fontSize':'16px'}} >
                                <svg width="40pt" height="40pt" fill="white" version="1.1" viewBox="0 0 100 100" style={{'margin':'-10px 0 0 -17px'}} xmlns="http://www.w3.org/2000/svg">
                                    <path d="m50 48.852-15.711-15.719c-1.0898-1.0508-2.8242-1.0391-3.8945 0.035157-1.0703 1.0703-1.0859 2.8047-0.035156 3.8945l15.707 15.715-15.715 15.711c-1.0859 1.0859-1.0859 2.8477 0 3.9336 1.0859 1.0859 2.8438 1.0859 3.9336 0l15.715-15.715 15.711 15.715c1.0859 1.0859 2.8477 1.0859 3.9336 0 1.0859-1.0859 1.0859-2.8477 0-3.9336l-15.715-15.711 15.715-15.711c1.0508-1.0898 1.0391-2.8203-0.035156-3.8945-1.0703-1.0703-2.8047-1.0859-3.8945-0.03125l-15.715 15.703z"/>
                                </svg>
                            </button>
                            <div style={{'width':'100%'}} >
                                {translate('Voeg een vrije keuze toe')}
                            </div>
                        </div>
                    </div>)
                    }

                    { (!this.props.mobile && freeChoiceButton === 'above_longlist') &&
                    (<div>

                        <button type="submit" className="btn btn--blue" onClick={this.addToShortlist.bind(this)}>
                            Voeg toe aan mijn lijst
                        </button>

                    </div>)
                    }

                </div>
            </div>
        );

    }
}

const mapStateToProps = (state) => {

    return {
       list: state.shortlist.list,
       formdata: state.formdata.freechoice
    }

}

export default connect(mapStateToProps, { saveFormFreechoice, addSongToShortlist, toggleHighlight,toggleFreeChoice,setSearchValue })( FormFreechoice );