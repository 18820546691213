import {Component} from 'react';

import * as st from "../redux/actions/StemtoolAction";
import {store} from "../redux/store";

class PageClear extends Component {

     constructor(props){
         super(props);
         
         // We have to KEEP the token on clear
         var _token = store.getState().stemtool._token;

         // Reset Redux
         st.resetApplication(_token)(store.dispatch);

         // Restore the token
         st.setToken(_token)(store.dispatch, store.getState);

         // Get slug from URL
         let slug = document.location.pathname.split('/')[1];

         // and redirect to step 1
         document.location.href = '/' + slug + '/1';
     }

    render() {
        return ( null );
    }

}


export default PageClear;