import React from 'react'

const Header = ({stemtool}) => {

    // @TODO: Probably best to get the actual channel name from somewhere instead of using split method.
    //  I couldn't find where to get it

    if(stemtool.content.redirect){
        return (
            <a href={stemtool.content.redirect}>
                <div className="visually-hidden">Bezoek de website van {stemtool.channel_slug.split('npo').join('npo ')}</div>
                <div className="bg--cover media media__header" aria-hidden="true" style={{ backgroundImage: 'url(' + stemtool.headerImage + ')' }}></div>
            </a>
        )
    } else {
        return (
            <div>
                <div className="visually-hidden">Bezoek de website van {stemtool.channel_slug.split('npo').join('npo ')}</div>
                <div className="bg--cover media media__header" aria-hidden="true" style={{ backgroundImage: 'url(' + stemtool.headerImage + ')' }}></div>
            </div>
        )

    }
}
export default Header
