import React, { Component } from 'react';

class YoutubePlayer extends Component {

    state = {
        playing: false
    }

    play = () => {

        if(window.ccm === undefined || window.ccm.getCategoryConsent('social') !== true) {
            window.open("https://www.youtube.com/watch?v=" + this.props.id);
        } else {
            this.setState({playing: true});
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(nextProps.id !== this.props.id && this.state.playing === true) {
            this.setState({playing: false});
        }

        return true;
    }

    render() {

        return (
            <div className="youtube-embed">
                {this.props.id && !this.state.playing && <div className="youtube-embed-placeholder" onClick={this.play}>
                    <img src={"http://i.ytimg.com/vi/" + this.props.id + "/hqdefault.jpg"} alt="Klik om videoclip te bekijken" />
                    <button className="youtube-embed-button-play"></button>
                </div>}

                {this.props.id && this.state.playing && <div className="youtube-embed-player">
                    <iframe src={"https://www.youtube-nocookie.com/embed/" + this.props.id + "?rel=0&autoplay=1&modestbranding=1"} title="youtube-video" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>}
            </div>
        );
    }
}

export default YoutubePlayer
