import React, { Component } from 'react';

class NotificationBar extends Component {

    render() {
        return (
            <div className="notification-bar-container">
                <div className={`col col--100 margin--bottom notification-bar notification-bar-color-${this.props.color}`}>
                    {this.props.content}
                </div>
            </div>
        );
    }
}

export default NotificationBar
