import * as type from '../types/LonglistType';

const defaultState = {
    settings: {
        limit: 40, // means records in one call
        treshold: 20 // when the treshold song gets visible we'll load a new batch
    },
    list: [],
    filter: {
        field: 'artist',
        value: 'random'
    },
    search: {
        isopen: true,
        value: ''
    }

};

export default function (state = defaultState, action) {
    switch (action.type) {
        case type.SET_SEARCH_VALUE:
            let newSetSearch = Object.assign({}, state.search);
            let newSetSearchFilter = Object.assign({}, state.filter);

            newSetSearchFilter.value = 'random';
            newSetSearch.value = action.payload;

            return {
                ...state,
                search: newSetSearch,
                filter: newSetSearchFilter
            }
        case type.SET_FILTER_FIELD:
            let newSetFilterField = Object.assign({}, state.filter);
            newSetFilterField.field = action.payload;

            return {
                ...state,
                filter: newSetFilterField
            }
        case type.SET_FILTER:
            // When we set the filter UNSET the search
            let newSetFilter = Object.assign({}, state.filter);
            let newSearch = Object.assign({}, state.search);

            newSetFilter.value = action.payload;
            newSearch.value = '';
            return {
                ...state,
                filter: newSetFilter,
                search: newSearch
            }
        case type.UNSET_SELECTED_BY_SONG:
			let newUnsetSelectedBySongList = state.list.slice(0);
            /**
             * We get a song and not an index.. so have to find the index first
             */
            for(var index in newUnsetSelectedBySongList)
                if(newUnsetSelectedBySongList[index]._id === action.song._id)
                   break;

            newUnsetSelectedBySongList[index]._selected = false;

            return {
                ...state,
                list: newUnsetSelectedBySongList
            }

        case type.SET_SELECTED:
			let newSetSelectedList = state.list.slice(0);
            newSetSelectedList[action.payload]._selected = true;

            return {
                ...state,
                list: newSetSelectedList
            }

        case type.UNSET_SELECTED:
			let newUnsetSelectedList = state.list.slice(0);
            newUnsetSelectedList[action.payload]._selected = false;

            return {
                ...state,
                list: newUnsetSelectedList
            }

        case type.FETCH_LONGLIST:
			let newFetchLongList = state.list.slice(0);
            // !! add the results to the list we already have
            if(action.payload.clear){
                newFetchLongList = [];
            }
            newFetchLongList.push(...action.payload.data);

            return {
                ...state,
                list: newFetchLongList
            }

        default:
            return state;

	}
}
