import * as type from '../types/FormType';
import * as longlistType from '../types/LonglistType';
import * as stemtooType from '../types/StemtoolType';
import {getLonglist} from "./LonglistAction";


export const clearGoogleRecaptcha = () => (dispatch) => {
    dispatch({
        type: type.SET_GOOGLE_RECAPTCHA,
        payload: { value : false }
    });
};


export const setGoogleRecaptcha = (recaptchaValue) => (dispatch) => {    
    dispatch({
        type: type.SET_GOOGLE_RECAPTCHA,
        payload: { value : recaptchaValue }      
    });            
};

export const setIsSubmitting = () => (dispatch) => {    
    dispatch({
        type: type.SET_IS_SUBMITTING      
    });            
};
export const unsetIsSubmitting = () => (dispatch) => {    
    dispatch({
        type: type.UNSET_IS_SUBMITTING      
    });            
};


export const setAllEnqueteTouced = () => (dispatch) => {
    
    dispatch({
        type: type.SET_ALL_ENQUETE_TOUCHED      
    });            
};
export const setAllFormTouced = () => (dispatch) => {
    
    dispatch({
        type: type.SET_ALL_FORM_TOUCHED      
    });            
};


export const toggleFreeChoice = () => (dispatch) => {
    
    dispatch({
        type: type.TOGGLE_TOOLBAR_FREECHOICE      
    });            
};

export const toggleFilter = (step) => (dispatch, getStore) => {

    // When showfilter is true the filter will be closed
    // Should reset the filter to random so all search things work
    if(getStore().formdata.toolbar.showfilter){
        // This one will reset the filter
        dispatch({
            type: longlistType.SET_FILTER,
            payload: 'random'
        });
    }

    dispatch({
        type: type.TOGGLE_TOOLBAR_FILTER
    });
    // And here we reload the longlist
    getLonglist(step.slug, 0, true)(dispatch, getStore);

};


export const saveFormFreechoice = (values, errors, touched) => (dispatch) => {
    
    dispatch({
        type: type.FREECHOICE_TO_REDUX,
        payload: {
            values: values,
            errors: errors,
            touched: touched
        }
    });            
};

export const saveEnquete = (values, errors, touched) => (dispatch) => {
    
    dispatch({
        type: type.ENQUETE_TO_REDUX,
        payload: {
            values: values,
            errors: errors,
            touched: touched
        }
    });           
};


export const saveForm = (values, errors, touched) => (dispatch) => {
    
    dispatch({
        type: type.FORMDATA_TO_REDUX,
        payload: {
            values: values,
            errors: errors,
            touched: touched
        }
    });           
};

export const setValid = (valid, currStep) => (dispatch, getStore) => {

    dispatch({
        type: stemtooType.SET_STEP_VALID,
        payload: {
            stepIndex: currStep.stepIndex,
            valid: valid
        }
    });
    
}
