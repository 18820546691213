import 'babel-polyfill';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

// redux
import { setElasticSearchSeed, getFlow, resetApplication, setToken } from './redux/actions/StemtoolAction';

import PageLonglist from './pages/PageLonglist';
import PageMotivation from './pages/PageMotivation';
import PageForm from './pages/PageForm';
import PageShare from './pages/PageShare';
import PageThankyou from './pages/PageThankyou';
import PageClosed from './pages/PageClosed';
import PageVerify from './pages/PageVerify';
import PageEnquete from './pages/PageEnquete';
import PageLogin from './pages/PageLogin';
import PageLogout from './pages/PageLogout';
import PageClear from './pages/PageClear';
import PageVerifyFromUrl from './pages/PageVerifyFromUrl';

import './scss/app.css';
import {store} from "./redux/store";

class App extends Component {

    /***************************************************************************
     *
     * Got this from the old version

     **************************************************************************/
    constructor (props) {
        if(process.env.REACT_APP_STEMTOOL_DEBUG === '1'){
            // show env vars (not so very secret)
            console.log(process.env);
        }

        super(props);
        this.state = { mobile: undefined };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentWillUnmount () {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions () {
      this.setState({
        mobile: window.innerWidth < 768
      });
    }


    componentWillMount() {

        this.updateWindowDimensions();

        window.addEventListener('resize', this.updateWindowDimensions);

        // !! Here we fiddle the slug out of the pathname
        var slug = document.location.pathname.split('/')[1];

        /***********************************************************************
         *
         * Reset application when changing slugs
         *
         **********************************************************************/
        if(this.props.stemtool.properties){
            // Now check the stored slug when we have one
            if(slug !== this.props.stemtool.properties.slug){

                // We have to KEEP the token on clear
                var _token = store.getState().stemtool._token;
                // reset
                this.props.resetApplication();
                // Restore the token
                this.props.setToken(_token);
            }
        }


        this.props.setElasticSearchSeed();
        /**
         * Gets the flow from the server
         * and moves it into the store
         */
        this.props.getFlow( typeof slug === 'undefined' ? '' : slug);

    }


    render() {

        if (this.props.stemtool.properties) {

            let {mobile} = this.state;

            // flow will have all meta data about the flow
            let stemtool = this.props.stemtool.properties;

            // Get all step meta dada
            let {steps} = stemtool;

            // Now create all routes for this app for this stemtool-slug
            let routeObjs  = steps.map( (step, i) => {

                // Calculate step, next and previous steps (and just pass all too)
                // Steps are static an not touched by Redux
                let propSteps = {
                    currStep : steps[i],
                    nextStep : steps[i+1] ? steps[i+1] : null,
                    prevStep : steps[i-1] ? steps[i-1] : null,
                    all : steps
                }

                /**
                 *
                 * Dunno how to make this small
                 * I gert an error when I use <${somevar} />
                 *
                 */
                switch(step.type){
                    case 'motivation':
                        return (<Route key={i} exact path={`/${stemtool.slug}/${i+1}`}
                            render={ (props) => { return <PageMotivation mobile={mobile} stemtool={stemtool} steps={propSteps} {...props} /> } } />  )
                    case 'form':
                        return (<Route key={i} exact path={`/${stemtool.slug}/${i+1}`}
                            render={ (props) => { return <PageForm mobile={mobile} stemtool={stemtool} steps={propSteps} {...props} /> } } />  )
                    case 'list':
                        return (<Route key={i} exact path={`/${stemtool.slug}/${i+1}`}
                            render={ (props) => { return <PageLonglist mobile={mobile} stemtool={stemtool} steps={propSteps} {...props} /> } } />  )
                    case 'thankyou':
                        return (<Route key={i} exact path={`/${stemtool.slug}/${i+1}`}
                            render={ (props) => { return <PageThankyou mobile={mobile} stemtool={stemtool} steps={propSteps} {...props} /> } } />  )
                    case 'verify':
                        return (<Route key={i} exact path={`/${stemtool.slug}/${i+1}`}
                            render={ (props) => { return <PageVerify mobile={mobile} stemtool={stemtool} steps={propSteps} {...props} /> } } />  )
                    case 'closed':
                        return (<Route key={i} exact path={`/${stemtool.slug}/${i+1}`}
                            render={ (props) => { return <PageClosed mobile={mobile} stemtool={stemtool} steps={propSteps} {...props} /> } } />  )
                    case 'enquete':
                        return (<Route key={i} exact path={`/${stemtool.slug}/${i+1}`}
                            render={ (props) => { return <PageEnquete mobile={mobile} stemtool={stemtool} steps={propSteps} {...props} /> } } />  )
                    case 'share':
                        return (<Route key={i} exact path={`/${stemtool.slug}/share/:hash`}
                            render={ (props) => { return <PageShare mobile={mobile} stemtool={stemtool} steps={propSteps} {...props} /> } } />  )
                    default:
                        return null;
                }
            } );
            
            // Add the login page
            routeObjs.push((<Route key={routeObjs.length} exact path={`/${stemtool.slug}/login`} render={ (props) => { return <PageLogin />} } />  ));
            routeObjs.push((<Route key={routeObjs.length} exact path={`/${stemtool.slug}/logout`} render={ (props) => { return <PageLogout />} } />  ));
            routeObjs.push((<Route key={routeObjs.length} exact path={`/${stemtool.slug}/clear`} render={ (props) => { return <PageClear />} } />  ));
            routeObjs.push((<Route 
                key={routeObjs.length} 
                exact 
                path={`/${stemtool.slug}/verify/:sessionHash/:verificationHash`} 
                render={ (props) => { 
                    return <PageVerifyFromUrl stemtool={this.props.stemtool} {...props} />} 
                } />
            ));
            routeObjs.push((<Route key={routeObjs.length} render={() => ( <Redirect to={`/${stemtool.slug}/1`} /> )} />  ));

            return (
                <div className={stemtool.channel_slug ? 'channel_' + stemtool.channel_slug : ''}>
                    <BrowserRouter>
                        <div className="container">
                            <Switch>
                                {routeObjs}
                            </Switch>
                        </div>
                    </BrowserRouter>
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        stemtool: state.stemtool
    }
}

export default connect(mapStateToProps, { setElasticSearchSeed, getFlow,resetApplication, setToken })(App);

