import React, { Component } from 'react'
import {store} from '../redux/store';

import ModalShareToInstagram from './ModalShareToInstagram';
import Analytics from '../model/Analytics';

var refToInstagramModal = null;

class Social extends Component {

    toggleInstagram() {
        refToInstagramModal.handleOpenModal();
    }

    setInstagramModal(ref, className) {
        refToInstagramModal = ref;
    }

    render() {
        // standard js
        let {protocol, host} = document.location;

        let {mobile, translate, stemtool, currStep} = this.props;

        let store_stemtool = store.getState().stemtool;

        let slug = store_stemtool.properties.slug;
        let twitter_share = store_stemtool.properties.content.twitter_share;
        let og_share = store_stemtool.properties.content.og_description;
        let {share_hash} = store_stemtool;

        let url = protocol + '//' + host + '/' + slug + '/share/' + share_hash;

        let facebook_url = 'http://www.facebook.com/sharer.php?u=' + url;
        let twitter_url = 'http://twitter.com/intent/tweet?url=' + url + '&text=' + encodeURIComponent(twitter_share);
        let whatsapp_url = 'https://api.whatsapp.com/send?text=' + encodeURIComponent(og_share + " ") + url;

        return (
                <div className='margin--bottom margin--top'>
                    <div className='padding--top--sm '>
                        <h2 className={`font ${mobile ? 'font-size' : 'font-size--md'}`} > {translate('Deel je stemlijst')} </h2>
                    </div>
                    <div className='socialMedia--container margin--top'>
                        <a href={facebook_url} target="_blank" onClick={ Analytics.click.bind(this, 'navigation', 'share-facebook') } className='btn btn--blank btn--ic-lg margin--xs'>
							<span className="visually-hidden">{translate('Deel via Facebook')}</span>
							<i className='icon icon__social icon__social--fb--light' aria-hidden="true" />
                        </a>
                        <a href={twitter_url} target="_blank" onClick={ Analytics.click.bind(this, 'navigation', 'share-twitter') }className='btn btn--blank btn--ic-lg margin--xs'>
							<span className="visually-hidden">{translate('Deel via Twitter')}</span>
                            <i className='icon icon__social icon__social--tw--light' aria-hidden="true" />
                        </a>
                        { stemtool.content.instagramStoriesImage && stemtool.content.instagramFeedImage ?
                        <a className='btn btn--blank btn--ic-lg margin--xs' onClick={this.toggleInstagram}>
							<span className="visually-hidden">{translate('Deel via Instagram')}</span>
                            <i className='icon icon__social icon__social--ig--light' aria-hidden="true" />
                        </a>
                        : null }
                        <a href={whatsapp_url} target="_blank" onClick={ Analytics.click.bind(this, 'navigation', 'share-whatsapp') } className='btn btn--blank btn--ic-lg margin--xs'>
							<span className="visually-hidden">{translate('Deel via Whatsapp')}</span>
							<i className='icon icon__social icon__social--wa--light' aria-hidden="true" />
                        </a>
                    </div>
                    { stemtool.content.instagramStoriesImage && stemtool.content.instagramFeedImage ? <ModalShareToInstagram init={this.setInstagramModal} stemtool={stemtool} mobile={mobile} translate={translate} currStep={currStep} /> : null }
                </div>
        )
    }
}
export default Social;
