/*******************************************************************************
 ************************** LONGLIST PAGE LAYOUT *******************************
 ******************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import Sticky from 'react-sticky-el';
import NotificationSystem from 'react-notification-system';

import SongDetails from '../components/SongDetails';
import StepContent from '../components/StepContent';
import Header from '../components/Header';
import MobileShortlistHeader from '../components/MobileShortlistHeader';
import ShortlistHeader from '../components/ShortlistHeader';
import Shortlist from '../components/Shortlist';

import FreechoiceHeader from '../components/FreechoiceHeader';
import Freechoice from '../components/Freechoice';

import FormToolbar from '../components/forms/FormToolbar';
import Navigation from '../components/Navigation';

import Longlist from '../components/Longlist';
import Modal from 'react-responsive-modal'
import YoutubePlayer from '../components/YoutubePlayer';

import Parser from 'html-react-parser';

import Page from './Page';

class PageLonglist extends Page {
    state = {
        open: false,
    };

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    render() {
        const { open } = this.state;
        let {mobile, highlight, stemtool, steps, history, mobile_show_shortlist} = this.props;
        let {currStep} = steps;

        var style = {
            NotificationItem: { // Override the notification item
                DefaultStyle: { // Applied to every notification, regardless of the notification level
                    // margin: '10px 5px 2px 1px'
                },

                success: { // Applied only to the success notification item
                    // color: 'red'
                }
            }
        };

        // https://www.youtube.com/embed/SLQDKuht6bc?autoplay=1&enablejsapi=1&origin=http%3A%2F%2Flocalhost%3A3000&widgetid=1
        return (
            <div className="container">
                {stemtool.content.youtubeEmbed && <Modal open={open} onClose={this.onCloseModal} little>
                    <h2>{stemtool.content.youtubeTitle}</h2>
                    <p>{Parser( stemtool.content.youtubeDesc ? stemtool.content.youtubeDesc : '')}</p>
                    <YoutubePlayer
                        id={stemtool.content.youtubeEmbed}
                    />
                </Modal>}

                {/* Popup */}
                <NotificationSystem ref="notificationSystem" style={style}/>

                {/* Top panel */}
                <header className="header-container col col--100 clear">
                    <Header stemtool={stemtool}/>
                    {stemtool.content.youtubeEmbed &&
                        <button className="modal-toggle" onClick={this.onOpenModal}>
                            <span className="modal-toggle-text">{this.translate('Hulp nodig?')}</span>
                            <svg className="modal-toggle-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path
                                    d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm2-13c0 .28-.21.8-.42 1L10 9.58c-.57.58-1 1.6-1 2.42v1h2v-1c0-.29.21-.8.42-1L13 9.42c.57-.58 1-1.6 1-2.42a4 4 0 1 0-8 0h2a2 2 0 1 1 4 0zm-3 8v2h2v-2H9z"/>
                            </svg>
                        </button>}
                </header>
                {/******************** MOBILE VERSION *****************************/}

                {mobile && <div className='col col--100 mobile--container clear'>

                    <div className={`container shortlist-container--mobile ${highlight ? 'expanded' : ''}`}>
                        <div className="panel--scroll-sm">
                            <SongDetails highlight={highlight} mobile={mobile} page={this} currStep={currStep} translate={this.translate} />
                        </div>
                    </div>

                    <div className="padding--mobile-block--bottom step--longlist">
                        {/* Info panel */}
                        <div className="padding--md panel--gray">
                            <StepContent currStep={currStep} translate={this.translate} />
                        </div>

                        {/* Form panel */}
                        <FormToolbar currStep={currStep} mobile={mobile} page={this}/>
                        <div className="padding no-padding-top">
                            <Longlist slug={stemtool.slug} currStep={currStep} mobile={mobile} page={this} translate={this.translate} />
                        </div>
                    </div>

                    <div
                        className={`container shortlist-container--mobile ${mobile_show_shortlist && !highlight ? 'expanded' : ''}`}>

                        <MobileShortlistHeader currStep={currStep} translate={this.translate}/>
                        <div className="padding--md panel--scroll-sm">
                            <Shortlist currStep={currStep} buttType="delete" mobile={mobile}/>
                            {
                                currStep.freeChoiceStrategy === 'add_to_max' || currStep.max_free_choice !== currStep.max 
                                ? <Freechoice mobile={mobile} currStep={currStep} buttType="delete"/> 
                                : null
                            }
                        </div>
                    </div>

                    <div className="container navigation-container--mobile">
                        <Navigation slug={stemtool.slug} steps={steps} history={history} translate={this.translate} />
                    </div>
                </div>}

                {/******************** DESKTOP VERSION *****************************/}
                {!mobile && <main className='flex col--100'>

                    {/* Info panel */}
                    <aside className="col col--40 sidebar clear">
                        <div className='sticky--container panel--gray'>
                            <Sticky mode="top" positionRecheckInterval={1}>
                                {highlight
                                    /* We have a highlight (the details of a song) */
                                    ? <div>
                                        <SongDetails highlight={highlight} mobile={mobile} page={this} currStep={currStep} translate={this.translate} />
                                    </div>
                                    /* Shortlist */
                                    : <div className='padding'>

                                        <StepContent currStep={currStep} translate={this.translate} />

                                        <div className='container clear'>
                                            <ShortlistHeader currStep={currStep} translate={this.translate}/>
                                            <Shortlist currStep={currStep} buttType="delete" mobile={mobile}/>
                                        </div>

                                        <div className='padding'/>

                                        { currStep.freeChoiceStrategy === 'add_to_max' || currStep.max_free_choice !== currStep.max
                                            ? <div className='container clear'>
                                                <FreechoiceHeader currStep={currStep} translate={this.translate}/>
                                                <Freechoice currStep={currStep} buttType="delete" mobile={mobile}/>
                                            </div>
                                            : null }
                                    </div>
                                }
                            </Sticky>
                        </div>
                    </aside>

                    {/* Form panel */}
                    <div className="col col--60 clear mainbar">
                        <div className='panel--white padding no-padding-top'>
                            <FormToolbar currStep={currStep} mobile={mobile} page={this}/>
                            <Longlist slug={stemtool.slug} currStep={currStep} mobile={mobile} page={this} translate={this.translate} />
                            <Navigation slug={stemtool.slug} steps={steps} history={history} mobile={mobile} translate={this.translate} />
                        </div>
                    </div>

                </main>}
            </div>)
    }
}

const mapStateToProps = (state) => {
    return {
        shortlist: state.shortlist.list,
        highlight: state.stemtool.highlight,
        toolbar: state.formdata.toolbar,
        mobile_show_shortlist: state.stemtool.mobile_show_shortlist,
        showModal: state.showModal
    }
}
export default connect(mapStateToProps)(PageLonglist);
