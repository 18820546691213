import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";

import { setGoogleRecaptcha } from '../../redux/actions/FormAction';

class Recaptcha  extends Component{

    handleChange = value => {
        // value is what you need to verify the user's response
        this.props.setGoogleRecaptcha( value );
        this.setState({ value });
    };

    render() {

        if( (this.props.session_hash || this.props._token) || !this.props.currStep.saveonnext ){
            return null;
        }

        return (
            <div className="Recaptcha">
                <ReCAPTCHA
                    style={{ display: "inline-block" }}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={this.handleChange}
                />
            </div>
        );
     }
}

const mapStateToProps = (state) => {
    return {
        session_hash: state.stemtool.session_hash,
        _token: state.stemtool._token   // For the frontend login
    }
}

export default connect(mapStateToProps, { setGoogleRecaptcha })(Recaptcha);
