import React, {Component} from 'react'

class Song extends Component {

    state = {_playState: false}

    onAdd(index, e) {
        e.stopPropagation()
        if (this.props.onAdd) {
            this.props.onAdd(index);
        }
    }

    onDelete(index, e) {
        e.stopPropagation()
        if (this.props.onDelete) {
            this.props.onDelete(index);
        }
    }

    onSongClick(index) {
        if (this.props.onSongClick) {
            this.props.onSongClick(index);
        }
    }

    onPlay(url, e) {
        e.stopPropagation()

        // Not totally react probably... but 'unplay all playing butts' (local stuff)
        var elements = document.getElementsByClassName('icon__play-playing');
        for (var i = 0; i < elements.length; i++) {
            elements[i].classList.add('icon__play');
            elements[i].classList.remove('icon__play-playing');
        }

        // and when we have a player configured as an attribute play the url
        if (this.props.parent._player) {
            let _playState = this.props.parent._player.playThis(url);

            this.setState({_playState: _playState}); // local graphics
        }
    }

    render() {

        /**
         * !! song on the SHARE page comes from the BACKEND !!
         */
        let {song, mobile} = this.props;
        let {_source} = song;
        let {currStep} = this.props;
        let {type} = this.props;
        let {index} = this.props;
        let {_playState} = this.state;


        /**
         *
         * @todo fix this one
         */
        let selected = song._selected ? true : false;

        // Check if there is an overrule image
        let image = null;
        if (_source.image) {
            image = _source.image;
        } else if (currStep.freeChoiceImage) {
            image = currStep.freeChoiceImage;
        } else {
            image = process.env.REACT_APP_BACKEND_PROTOCOL_AND_URL + 'images/stemtool/default/100x100.png'
        }

        var title = '';
        if (mobile) {
            let tmp = _source.title.split(' ');
            for (var i = 0; i < 8; i++) {
                if (tmp[i])
                    title += (tmp[i] + ' ');
            }
            if (tmp[i])
                title += '…';


        } else {
            title = _source.title;
        }

        // @TODO: NPOWT1284: Set proper language tags, we'd need to know which country a song is from though. We also need to test this on JAWS and Nvidia since I can't get it to work properly on VoiceOver

        return (
            <div className="container--song" onClick={() => this.onSongClick(index)}>
                <div>
                    <div className="link">

                        <div
                            className={`flex--center ${ (type === 'add' || type === 'edit') ? 'evt--pointer' : '' } song color--gray87 ${ selected ? 'panel--blue panel--blue--h' : 'panel--gray87 panel--gray87--h'}`}>

                            <div className="song--image">
                                {(image) && <img
                                    className="song--image"
                                    src={image} alt=""/>}
                            </div>
                            <div>
                                <h2 className="track-info">
                                    <span className="song--artist font-size--sm"> {_source.artist} </span>
                                    <span className="song--title font-size--sm"> {title} {_source.year ? <span className="song--year">({_source.year})</span> : null}</span>
                                </h2>

                            </div>
                            <div className="song--action">
                                {( (type === 'add' || type === 'share' || type === 'thankyou') && _source.audio) ?
                                    <button className="btn btn--blank btn--h" aria-pressed={_playState ? true : false} aria-label="Afspelen" onClick={this.onPlay.bind(this, _source.audio)}>
                                        <i className={`icon icon__play${_playState ? '-playing' : ''} `}/>
                                    </button> : ''}

                                {(type === 'add' || type === 'delete') ?
                                    <button className="btn btn--blank btn--h" onClick={(type === 'add' && !selected) ? this.onAdd.bind(this, index) : this.onDelete.bind(this, index)} aria-pressed={(type === 'add' && !selected) ? false : true}>
                                        <i className={`icon ${(type === 'add' && !selected) ? "icon__add--black" : "icon__delete"}`}/>
                                        <span className="visually-hidden">Nummer {title} van {_source.artist} als favoriet {`${(type === 'add' && !selected) ? "toevoegen" : "verwijderen"}`}</span>
                                    </button> : null}

                                {(type === 'edit') ?
                                    <button className="btn btn--blank btn--h">
                                        <i className={`icon icon__edit${song._motivationVisible ? '-blue' : ''}`}/>
                                        <span className="visually-hidden">Motivatie opgeven voor het nummer {title} van {_source.artist}.</span>
                                    </button> : null}
                            </div>
                        </div>
                    </div>
                </div>

            </div>)
    }

}

export default Song;
