import React, { Component } from "react";
import { connect } from "react-redux";
import Parser from "html-react-parser";
import { saveEnquete, setValid } from "../../redux/actions/FormAction";

class FormEnquete extends Component {
    // Need this
    questions = {};
    assocQuestions = [];
    required = {};

    formHasErrors = true;
    componentDidMount() {
        // Move the config into this.questions for easy access (just convenient)
        this.props.steps.currStep.questions.forEach((field, i) => {
            this.questions[i] = field;
            // probably redundant but i'm too lazy to fix. Ne my guest
            this.assocQuestions[field.col] = field;
        });

        // Copy the current redux data into the state (initial load, you need this!)
        this.setState({ formdata: this.props.formdata });
    }

    componentWillReceiveProps(nextProps) {
        // Fire the errors when the next butt is clicked
        var errors = this.myValidator(nextProps.formdata.values);
        // Add them
        nextProps.formdata.errors = errors;
        // And move them into the state
        this.setState({ formdata: nextProps.formdata });
    }

    myValidator = (values) => {
        this.formHasErrors = false;
        var errors = {};

        // Make an array with the fields that have a value
        var fieldHasValue = [];
        for (var fieldname in values) {
            // checkboxes look like col_1[val 1] col_1[val 2] etc
            // Check if one or more of those checboxes are checked
            let temp = fieldname.split("[");
            // We found a checkbox
            if (temp.length > 1) {
                // It has been set to true
                if (!fieldHasValue[temp[0]] && values[fieldname]) {
                    // So now this list of checboxes has at least 1 checked
                    fieldHasValue[temp[0]] = true;
                }
            } else {
                // Here everything else than a checkbox
                if (this.assocQuestions[fieldname].type === "shortlist") {
                    // When shortlist... when the seelcted value is the same as the default value it's NOT selected
                    fieldHasValue[temp[0]] =
                        values[fieldname] !==
                        this.props.formdata.default[fieldname];
                } else if (this.assocQuestions[fieldname].type === "select") {
                    // When select... when NO default value is given an extra option has been made
                    // with value --- and text --- Means we can not have other options
                    fieldHasValue[temp[0]] =
                        values[fieldname] !== "---" ? true : false;
                } else {
                    fieldHasValue[temp[0]] = values[fieldname] ? true : false;
                }
            }
        }

        for (fieldname in this.required) {
            if (!fieldHasValue[fieldname] && this.required[fieldname]) {
                this.formHasErrors = true;
                errors[fieldname] = this.props.translate(
                    "Beantwoord deze vraag alsjeblieft"
                );
            }
        }
        return errors;
    };

    /**
     * This one creates the form
     */
    myForm({ values, errors, touched }) {
        // Easy readin
        let formArgs = arguments[0];

        var myFormFields = [];

        for (var index in this.questions) {
            /**
             * this.questions does have the CONFIG
             * values (from Formik) has the user data!
             *
             * Please do not 'shorten' this code.
             * The reason why it is like this is per element very configurable
             *
             */
            let field = this.questions[index];
            let name = field.col;

            if (!field.label) field.label = "";

            this.required[name] = field.required;

            switch (field.type) {
                case "text":
                    myFormFields.push(
                        <div className="form-element" key={name}>
                            <label htmlFor={name} className="font-size--sm">
                                {Parser(field.label)}
                                {field.required && " *"}
                            </label>
                            <input
                                type="text"
                                maxLength="255"
                                value={values[name] ? values[name] : ""}
                                placeholder={field.placeholder}
                                id={name}
                                name={name}
                                className="input border font-size--sm"
                                autoComplete={name}
                                onChange={this.onChange.bind(this, formArgs)}
                                onBlur={this.onBlur.bind(this, formArgs)}
                            />
                            {touched[name] && errors[name] && (
                                <div className="error">{errors[name]}</div>
                            )}
                        </div>
                    );
                    break;
                case "textarea":
                    myFormFields.push(
                        <div className="form-element" key={name}>
                            <label htmlFor={name} className="font-size--sm">
                                {Parser(field.label)}
                                {field.required && " *"}
                            </label>
                            <textarea
                                type="text"
                                value={values[name] ? values[name] : ""}
                                placeholder={field.placeholder}
                                id={name}
                                name={name}
                                className="input border font-size--sm"
                                autoComplete={name}
                                onChange={this.onChange.bind(this, formArgs)}
                                onBlur={this.onBlur.bind(this, formArgs)}
                            />
                            {touched[name] && errors[name] && (
                                <div className="error">{errors[name]}</div>
                            )}
                        </div>
                    );
                    break;
                case "checkbox":
                    let boxes = field.values.map((value, i) => {
                        return (
                            <div className="enquete_line" key={name + i}>
                                <input
                                    type="checkbox"
                                    id={"st_checkbox" + name + value}
                                    value={value}
                                    name={name + "[" + value + "]"}
                                    defaultChecked={
                                        values[name + "[" + value + "]"]
                                            ? true
                                            : false
                                    }
                                    onChange={this.onChange.bind(
                                        this,
                                        formArgs
                                    )}
                                    onBlur={this.onBlur.bind(this, formArgs)}
                                />
                                <label
                                    className="enquete_checkbox"
                                    htmlFor={"st_checkbox" + name + value}
                                />
                                <label
                                    className="enquete_checkbox_text"
                                    htmlFor={"st_checkbox" + name + value}
                                >
                                    {value}
                                </label>
                            </div>
                        );
                    });
                    myFormFields.push(
                        <div className="flex form-element" key={name}>
                            <label htmlFor={name} className="font-size--sm">
                                {Parser(field.label)}
                                {field.required && " *"}
                            </label>
                            <div className="enquete_antilabel enquete_checkboxes">
                                {boxes}
                            </div>
                            {touched[name] && errors[name] && (
                                <div className="error">{errors[name]}</div>
                            )}
                        </div>
                    );
                    break;
                case "radio":
                    let radios = field.values.map((value, i) => {
                        return (
                            <div
                                className="enquete_line enquete_line_radio"
                                key={name + i}
                            >
                                <input
                                    type="radio"
                                    id={"st_radio" + name + value}
                                    name={name}
                                    value={value}
                                    defaultChecked={
                                        values[name] === value ? true : false
                                    }
                                    onChange={this.onChange.bind(
                                        this,
                                        formArgs
                                    )}
                                    onBlur={this.onBlur.bind(this, formArgs)}
                                />
                                <label htmlFor={"st_radio" + name + value}>
                                    {value}
                                </label>
                            </div>
                        );
                    });
                    myFormFields.push(
                        <div className="flex form-element" key={name}>
                            <label className="font-size--sm">
                                {Parser(field.label)}
                                {field.required && " *"}
                            </label>
                            <div className="enquete_antilabel enquete_radios">
                                {radios}
                            </div>
                            {touched[name] && errors[name] && (
                                <div className="error">{errors[name]}</div>
                            )}
                        </div>
                    );
                    break;
                case "radio-other":
                    let normalradios = field.values.map((value, i) => {
                        return (
                            <div
                                className="enquete_line enquete_line_radio"
                                key={name + i}
                            >
                                <input
                                    type="radio"
                                    id={"st_radio" + name + value}
                                    name={name}
                                    value={value}
                                    defaultChecked={
                                        values[name] === value ? true : false
                                    }
                                    onChange={this.onChange.bind(
                                        this,
                                        formArgs
                                    )}
                                    onBlur={this.onBlur.bind(this, formArgs)}
                                />
                                <label htmlFor={"st_radio" + name + value}>
                                    {value}
                                </label>
                            </div>
                        );
                    });
                    myFormFields.push(
                        <div className="flex form-element" key={name}>
                            <label className="font-size--sm">
                                {Parser(field.label)}
                                {field.required && " *"}
                            </label>
                            <div className="enquete_antilabel enquete_radios">
                                {normalradios}
                                <div
                                    className="enquete_line enquete_line_radio"
                                    key={name + "other"}
                                >
                                    <input
                                        type="radio"
                                        id={"st_radio" + name + "other"}
                                        name={name}
                                        value="other"
                                        defaultChecked={
                                            !values[name] ||
                                            !field.values.includes(values[name])
                                        }
                                    />
                                    <label
                                        htmlFor={"st_radio" + name + "other"}
                                    >
                                        Anders, namelijk:
                                        <input
                                            type="text"
                                            maxLength="255"
                                            id={name}
                                            name={name}
                                            defaultValue={
                                                !field.values.includes(
                                                    values[name]
                                                ) && values[name] !== "other"
                                                    ? values[name]
                                                    : null
                                            }
                                            className="input border font-size--sm"
                                            autoComplete={name}
                                            onChange={this.onOtherFieldChange.bind(
                                                this,
                                                formArgs
                                            )}
                                            onBlur={this.onOtherFieldBlur.bind(
                                                this,
                                                formArgs
                                            )}
                                        />
                                    </label>
                                </div>
                            </div>
                            {touched[name] && errors[name] && (
                                <div className="error">{errors[name]}</div>
                            )}
                        </div>
                    );
                    break;
                case "select":
                    let options = field.values.map((value, i) => {
                        // When no default value use first value
                        return (
                            <option key={name + i} value={value}>
                                {value}
                            </option>
                        );
                    });
                    // When no default value OR defval === --- create ---
                    // De def val is set in the Form reducer when no there
                    if (
                        !this.props.formdata.default[name] ||
                        this.props.formdata.default[name] === "---"
                    ) {
                        options.unshift(
                            <option key={"first_select"} value="---">
                                -- {this.props.translate("Maak een keuze")} --
                            </option>
                        );
                        // when we have a default val use that one
                        var defaultVal = "---";
                    } else {
                        // when we have a default val use that one
                        defaultVal = this.props.formdata.default[name];
                    }

                    // When no default value use first value
                    myFormFields.push(
                        <div className="flex form-element" key={name}>
                            <label className="font-size--sm">
                                {Parser(field.label)}
                                {field.required && " *"}
                            </label>
                            <select
                                id={name}
                                name={name}
                                className="input border font-size--sm"
                                value={values[name] ? values[name] : defaultVal}
                                autoComplete={name}
                                onChange={this.onChange.bind(this, formArgs)}
                                onBlur={this.onBlur.bind(this, formArgs)}
                            >
                                {options}
                            </select>
                            {touched[name] && errors[name] && (
                                <div className="error">{errors[name]}</div>
                            )}
                        </div>
                    );
                    break;
                case "likert-5":
                    // eslint-disable-next-line
                    let likertbuttons = [1, 2, 3, 4, 5].map((value, i) => {
                        return (
                            <div
                                className="enquete_line enquete_line_likert"
                                key={name + i}
                            >
                                {index === 0 ||
                                this.questions[index - 1].type !==
                                    "likert-5" ? (
                                    <label htmlFor={"st_radio" + name + value}>
                                        {value}
                                    </label>
                                ) : null}
                                <input
                                    type="radio"
                                    id={"st_radio" + name + value}
                                    name={name}
                                    value={value}
                                    defaultChecked={
                                        values[name] === value ? true : false
                                    }
                                    onChange={this.onChange.bind(
                                        this,
                                        formArgs
                                    )}
                                    onBlur={this.onBlur.bind(this, formArgs)}
                                />
                            </div>
                        );
                    });
                    myFormFields.push(
                        <div className="flex form-element" key={name}>
                            {field.placeholder ? (
                                <label className="font-size--sm">
                                    {Parser(field.placeholder)}
                                </label>
                            ) : null}
                            <div className="enquete_antilabel enquete_likert">
                                <div className="likert_subject">
                                    {field.label}
                                    {field.required && " *"}
                                </div>
                                <div className="likert_radiobuttons">
                                    {likertbuttons}
                                </div>
                            </div>
                            {touched[name] && errors[name] && (
                                <div className="error">{errors[name]}</div>
                            )}
                        </div>
                    );
                    break;
                case "shortlist":
                    options = this.props.shortlist.map((song, i) => {
                        let artistTitle =
                            song._source.artist + " - " + song._source.title;
                        let optionValue =
                            song._id !== "0" ? song._id : song.artistTitle;
                        // When freechoice ( song._id === '0' ) we use the artistTitle string, else use the song id
                        return (
                            <option key={name + i} value={optionValue}>
                                {artistTitle}
                            </option>
                        );
                    });

                    // The values field from the backend makes the first element
                    if (this.questions[index].values) {
                        options.unshift(
                            <option
                                key={"first_shortlist"}
                                value={this.questions[index].values}
                            >
                                {this.questions[index].values}
                            </option>
                        );
                    }

                    // When no default value use first value
                    let song = this.props.shortlist[0];
                    myFormFields.push(
                        <div className="flex form-element" key={name}>
                            <label className="font-size--sm">
                                {Parser(field.label)}
                                {field.required && " *"}
                            </label>
                            <select
                                id={name}
                                name={name}
                                className="input border font-size--sm"
                                value={
                                    values[name]
                                        ? values[name]
                                        : song._id !== "0"
                                        ? song._id
                                        : song.artistTitle
                                }
                                autoComplete={name}
                                onChange={this.onChange.bind(this, formArgs)}
                                onBlur={this.onBlur.bind(this, formArgs)}
                            >
                                {options}
                            </select>
                            {touched[name] && errors[name] && (
                                <div className="error">{errors[name]}</div>
                            )}
                        </div>
                    );
                    break;

                default:
                    break;
            }
        }

        return (
            <form className="form form--dynamic form-3 enquete">
                {myFormFields}
            </form>
        );
    }

    // Handle local state of the form
    doFormLogic(values, errors, touched, e) {
        var updateReduxOnChange = true;

        switch (e.target.type) {
            case "checkbox":
                // Delete the default value for a checkbox when none is set
                // will look like {col_5:null}
                // This one does not exist when a default value is set
                let name = e.target.name.split("[")[0];
                if (values[name]) {
                    delete values[name];
                }
                values[e.target.name] = e.target.checked;
                break;
            case "radio":
                values[e.target.name] = e.target.value;
                break;
            case "select-one":
                values[e.target.name] = e.target.value;
                break;
            case "text":
            case "textarea":
                // Need the value here
                values[e.target.name] = e.target.value;
                // These questions should be updated by an onblur
                updateReduxOnChange = false;
                break;
            default:
        }

        // Set the errors
        errors = this.myValidator(values, errors, e);

        this.setState({
            formdata: { values: values, errors: errors, touched: touched },
        });

        return { errors, values, touched, updateReduxOnChange };
    }

    /***************************************************************************
     *
     * !! This one calls Redux to set the STEP to valid or not !!
     * !! Needed by the flow.. butts get grey etc...
     *
     **************************************************************************/
    setStepValid() {
        let { formstates } = this.props;
        let { currStep } = this.props.steps;

        let stepIsValid = formstates[currStep.stepIndex].valid;

        var valid = null;
        if (stepIsValid && this.formHasErrors) {
            valid = false;
        } else if (!stepIsValid && !this.formHasErrors) {
            valid = true;
        }
        // Only call redux on a change
        if (valid !== null) {
            this.props.setValid(valid, currStep);
        }
    }

    onChange({ values, errors, touched }, e) {
        let res = this.doFormLogic(values, errors, touched, e);

        // only save checkbox
        if (res.updateReduxOnChange) {
            // When we update redux....
            this.props.saveEnquete(res.values, res.errors, res.touched);
        }
        this.setStepValid();
    }

    onBlur({ values, errors, touched }, e) {
        // Set touched
        let temp = e.target.name.split("[");
        // We found a checkbox... extract the real fieldname
        if (temp.length > 1) {
            var fieldName = temp[0];
        } else {
            fieldName = e.target.name;
        }

        touched[fieldName] = true;

        let res = this.doFormLogic(values, errors, touched, e);

        // Save when not saved already
        if (!res.updateReduxOnChange) {
            this.props.saveEnquete(res.values, res.errors, res.touched);
        }
    }

    onOtherFieldChange({ values, errors, touched }, e) {
        document.getElementById(
            "st_radio" + e.target.name + "other"
        ).checked = true;
        this.onChange({ values, errors, touched }, e);
    }

    onOtherFieldBlur({ values, errors, touched }, e) {
        document.getElementById(
            "st_radio" + e.target.name + "other"
        ).checked = true;
        this.onBlur({ values, errors, touched }, e);
    }

    render() {
        if (this.state) {
            return this.myForm(this.state.formdata);
        } else {
            return null;
        }
    }
}

// Redux
const mapStateToProps = (state) => {
    return {
        formdata: state.formdata.enquete,
        formstates: state.stemtool.formstates,
        shortlist: state.shortlist.list,
    };
};

export default connect(
    mapStateToProps,
    { saveEnquete, setValid }
)(FormEnquete);
