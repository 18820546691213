import React, {Component} from 'react';
import {connect} from 'react-redux';
import Sticky from 'react-sticky-el';

import {setSearchValue} from '../../redux/actions/LonglistAction';
import {toggleFreeChoice, toggleFilter} from '../../redux/actions/FormAction';

import FormFreechoice from './FormFreechoice';
import Filter from './Filter';

import ModalFreechoice from '../ModalFreechoice';
import ModalFilter from '../ModalFilter';

var refToModalFreechoice = null;
var refToModalFilter = null;

class FormToolbar extends Component {

    timer = null;

    constructor(props) {
        super(props);
        this.state = {
            searchval: '',
            modalIsOpen: false
        };
    }

    getModal(ref, className) {

        // Get a ref to the modal of the forms
        switch (className) {
            case 'ModalFreechoice':
                refToModalFreechoice = ref;
                break;
            case 'ModalFilter':
                refToModalFilter = ref;
                break;
            default:
                refToModalFilter = null;
        }

    }

    componentDidMount() {
        // Copy the current redux data into the state
        this.setState({searchval: this.props.search.value});
    }

    componentWillReceiveProps(nextProps) {
        // Clear the search val when we cleared it in redux
        this.setState({searchval: nextProps.search.value});

    }

    // When the search changes
    onChange = (e) => {

        var value = e.target.value;

        this.setState({searchval: value});

        // Only want after some time so clear the timer when we're here again when its
        if (this.timer) {
            clearTimeout(this.timer);
        }

        // We wait a bit before we send (or get cleared)
        this.timer = setTimeout(() => {
            this.props.setSearchValue(value, this.props.currStep);
        }, 400);
    };

    toggleFreechoice = () => {

        if (this.props.mobile) {
            refToModalFreechoice.handleOpenModal();
        } else {
            this.props.toggleFreeChoice();
            if (this.props.toolbar.showfilter) {
                this.props.toggleFilter(this.props.currStep);
            }
        }

    }

    toggleFilter = () => {

        if (this.props.mobile) {
            refToModalFilter.handleOpenModal();
        } else {
            this.props.toggleFilter(this.props.currStep);

            if (this.props.toolbar.showfreechoice) {
                this.props.toggleFreeChoice();
            }
        }

    }

    render() {

        let {mobile, toolbar, currStep, page} = this.props;
        let {translate} = page;
        let {freeChoiceButton} = currStep;

        if (mobile) {
            /*******************************************************************
             * MOBILE VERSION
             ******************************************************************/

            var filterTextExtra;
            var filterActiveClass;
            if (
                (this.props.filter.value.indexOf('all') === -1) && (this.props.filter.value.indexOf('random') === -1) && (this.props.filter.value)
            ) {
                filterTextExtra = ': ' + this.props.filter.value.toUpperCase();
                filterActiveClass = 'filter--active';
            }

            return (
                <div>

                    {/* The modals */}
                    <ModalFilter init={this.getModal} currStep={currStep} mobile={mobile} translate={translate}/>
                    <ModalFreechoice init={this.getModal} currStep={currStep} mobile={mobile} translate={translate} page={page}/>

                    <Sticky stickyClassName="toolbar sticky sticky--toolbar mobile" mode="top" positionRecheckInterval={1}>

                        <div className="filter--container padding clear">

                            <div className={"col col--" + (freeChoiceButton === 'above_longlist' ? '100' : '66') + " clear"}>
                                <div className="searchContainer">
                                    <label className="visually-hidden" htmlFor="search">{translate('Zoek een nummer')}</label>
                                    <input id="search" type="search" onChange={this.onChange.bind(this)} value={this.state.searchval}
                                           placeholder={translate('Zoek een nummer')}
                                           className="input search border border--radius-sm font-size--sm input"/>
                                    {!this.state.searchval && <i className="icon icon__search"></i>}
                                </div>
                            </div>

                            {freeChoiceButton !== 'above_longlist' ?
                            <div className="col col--33 clear">
                                <div className={freeChoiceButton + ' ' + filterActiveClass}>
                                    <button className={'btn btn--filter btn--blank'} active="false" onClick={this.toggleFilter}>
                                        <i className="icon icon__filter"></i>
                                        <span>
                                            <p>{filterTextExtra ? translate('Filter op') + filterTextExtra : translate('Filter')}</p>
                                        </span>
                                    </button>
                                    <div className="clear"/>
                                </div>
                            </div>
                            : null}

                            {freeChoiceButton === 'above_longlist' && currStep.max_free_choice > 0 ?
                            <div className="flexrow">

                                <div className={'margin--top--standard ' + freeChoiceButton + ' ' + filterActiveClass}>
                                    <button className={'btn btn--filter btn--blank'} active="false" onClick={this.toggleFilter}>
                                        <i className="icon icon__filter"></i>
                                        <span>
                                            <p>{filterTextExtra ? translate('Filter op') + filterTextExtra : translate('Filter')}</p>
                                        </span>
                                    </button>
                                    <div className="clear"/>
                                </div>


                                <div className="margin--top--standard">
                                    <button className="btn btn--blank btn--add-freechoice" active="false" onClick={this.toggleFreechoice}>
                                        <i className="icon icon__add"></i>
                                        <span className="freechoice">
                                            <p>{translate('Voeg vrije keuze toe')}</p>
                                        </span>
                                    </button>
                                    <div className="clear"/>
                                </div>
                            </div>
                            : null}

                        </div>

                    </Sticky>
                </div>

            );

        } else {
            /*******************************************************************
             * DESKTOP VERSION
             ******************************************************************/
            return (
                <Sticky stickyClassName="toolbar sticky sticky--toolbar desktop" mode="top" positionRecheckInterval={1}>
                    <div className="filter--container padding--bottom padding--top">


                        <div className={`col col--${freeChoiceButton === 'above_longlist' && currStep.max_free_choice > 0 ? 40 : 80} clear`}>
                            <div className="searchContainer">
                                <label className="visually-hidden" htmlFor="search">{translate('Zoek een nummer')}</label>
                                <input id="search" type="search" onChange={this.onChange.bind(this)} value={this.state.searchval}
                                       placeholder={translate('Zoek een nummer')}
                                       className="input search border border--radius-sm font-size--sm input"/>
                                {!this.state.searchval && <i className="icon icon__search"></i>}
                            </div>
                        </div>

                        <div className={`col col--20 clear`}>
                            <button className="btn btn--blank btn--filter" active="false" onClick={this.toggleFilter}>
                                <i className="icon icon__filter"></i>
                                <span>
                                    {translate('Filter')}
                                </span>
                            </button>
                        </div>

                        {freeChoiceButton === 'above_longlist' && currStep.max_free_choice > 0 ?
                            <div className={`col col--40 clear`}>
                                <button className="btn btn--blank btn--add-freechoice" active="false" onClick={this.toggleFreechoice}>
                                    <i className="icon icon__add"></i>
                                    <span className="freechoice">
                                        {translate('Voeg vrije keuze toe')}
                                    </span>
                                </button>
                                <div className="clear"/>
                            </div> : null
                        }


                        <div>
                            <div className="clear"/>
                        </div>
                        {toolbar.showfreechoice &&
                        <FormFreechoice currStep={currStep} translate={translate} page={page}/>}
                        {toolbar.showfilter && <Filter currStep={currStep} translate={translate}/>}
                    </div>
                </Sticky>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        filter: state.longlist.filter,
        search: state.longlist.search,
        toolbar: state.formdata.toolbar
    }
}
export default connect(mapStateToProps, {setSearchValue, toggleFreeChoice, toggleFilter})(FormToolbar);
