import * as type from '../types/FormType';

const defaultState = {
    /**
     * Have to do some here .. have to get them from the backend I guess
     */
    naw: {
        values: {
            name: '',
            city: '',
            postcode: '',
            country: '',
            email: '',
            phone: '',
            birthday: '',
            gender: '',
            npocancallme: false,
            newsletter: false,
            accept: false,
            sendscheme: false
        },
        errors: {},
        touched: {}
    },
    freechoice: {
        values: {artist: '',title: ''},
        errors: {},
        touched: {}
    },
    toolbar: {
        showfreechoice: false,
        showfilter: false
    },
    enquete: {
        default: {},
        values: {},
        errors: {},
        touched: {}
    },
    issubmitting: false,
    recaptcha: false
};

export default function (state = defaultState, action) {

    switch (action.type) {
        case type.SET_GOOGLE_RECAPTCHA:
            return {
                ...state,
                recaptcha: action.payload.value
            }

        case type.SET_IS_SUBMITTING:
            return {
                ...state,
                issubmitting: true
            }
        case type.UNSET_IS_SUBMITTING:
            return {
                ...state,
                issubmitting: false
            }
        case type.SET_ALL_FORM_TOUCHED:

            return {
                ...state,
                naw: {
                    values: state.naw.values,
                    errors: state.naw.errors,
                    touched: {}
                }
            }

        case type.SET_ALL_ENQUETE_TOUCHED:
            var enquete = Object.assign({}, state.enquete);
            for(var fieldname in state.enquete.values){
                enquete.touched[fieldname] = true;
            }

            return {
                ...state,
                enquete: enquete
            }


        /**
         * Have to set the default values for the enquete
         */
        case type.INIT_ENQUETE:

            var values = {};
            action.payload.forEach( (step) => {

                // Find enquete
                if(step.type === 'enquete'){

                    // Set default values
                    step.questions.forEach((question) => {

                        switch(question.type){
                            case 'text':
                            case 'textarea':
                            case 'shortlist':
                                values[question.col] = question.values;
                                break;
                            case 'select':
                                values[question.col] = question.placeholder ? question.placeholder : '---';
                                break;
                            case 'radio':
                                values[question.col] = question.placeholder;
                                break;
                            case 'checkbox':
                                if(question.values){
                                    question.values.forEach((item, i) => {
                                        if( question.placeholder ){
                                            question.placeholder.forEach(( defval ) => {
                                                if(defval === item){
                                                    values[ question.col + '[' + item + ']' ] = true;
                                                }
                                            });
                                        }
                                    });
                                }
                                if( question.placeholder.length === 0 ){
                                    values[ question.col ] = null;
                                }
                                break;
                            default:
                        }
                    });
                }
            });


            return {
                ...state,
                enquete: {
                    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                    // !!! YOU NEED THE "Object.assign" HERE .. Otherwise, when a value changes the default will change too (js object ref) !!!
                    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                    default: Object.assign({},values),
                    values: values,
                    errors: {},
                    touched: {}
                }
            }
        case type.TOGGLE_TOOLBAR_FILTER:
            let newToggleToolbarFilter = Object.assign({}, state.toolbar);
            newToggleToolbarFilter.showfilter = !newToggleToolbarFilter.showfilter;

            return {
                ...state,
                toolbar: newToggleToolbarFilter
            }

        case type.TOGGLE_TOOLBAR_FREECHOICE:
            let newToggleToolbarFreechoice = Object.assign({}, state.toolbar);
            newToggleToolbarFreechoice.showfreechoice = !newToggleToolbarFreechoice.showfreechoice;

            return {
                ...state,
                toolbar: newToggleToolbarFreechoice
            }

        case type.FREECHOICE_TO_REDUX:
            return {
                ...state,
                freechoice: {
                    values: action.payload.values,
                    errors: action.payload.errors,
                    touched: action.payload.touched
                }
            }
        case type.ENQUETE_TO_REDUX:
            // Only save in redux.. save to backend is done in the Navigation component
            return {
                ...state,
                enquete: {
                    default: state.enquete.default,
                    values: action.payload.values,
                    errors: action.payload.errors,
                    touched: action.payload.touched
                }
            }

        case type.FORMDATA_TO_REDUX:
            // Only save in redux.. save to backend is done in the Navigation component
            return {
                ...state,
                naw: {
                    values: action.payload.values,
                    errors: action.payload.errors,
                    touched: action.payload.touched
                }
            }
        default:
            return state;
	}
}
