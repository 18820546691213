import React from "react";
import StepContent from "../components/StepContent";
import Header from "../components/Header";
import Page from "./Page";
import NotificationBar from "../components/NotificationBar";
import { connect } from "react-redux";

class PageVerify extends Page {
  constructor(props) {
    super(props);

    // Reset stemtool after everything has loaded, so it will start over when refreshed
    setTimeout(() => {
      localStorage.removeItem("persist:root");
    }, 2000);
  }

  render() {
    let { mobile, stemtool, steps } = this.props;
    let { currStep } = steps;

    // Replace placeholder in body text with e-mailaddress
    currStep.content.body = currStep.content.body.replace(
      "[[submission_email]]",
      this.props.formdata.values.email
    );

    /******************** MOBILE VERSION *****************************/
    return (
      <div className="container">
        {/* Top panel */}
        <header className="header-container col col--100 clear">
          <Header stemtool={stemtool} />
        </header>
        {/******************** MOBILE VERSION *****************************/}
        {mobile && (
          <div className="col col--100 mobile--container clear">
            <div className="padding--mobile-block--bottom panel--gray">
              <div className="padding--md margin--bottom">
              <NotificationBar
              color="success"
              content={this.translate(
                "We hebben je zojuist een bevestigingsmail gestuurd"
              )}
            />
                <StepContent currStep={currStep} translate={this.translate} />
              </div>
            </div>
          </div>
        )}
        {/******************** DESKTOP VERSION *****************************/}
        {!mobile && (
          <div>
            <NotificationBar
              color="success"
              content={this.translate(
                "We hebben je zojuist een bevestigingsmail gestuurd"
              )}
            />
            <main className="flex col--100">
              <div className="col col--60 clear">
                <div className="sticky--container panel--gray">
                  <div className="padding">
                    <StepContent
                      currStep={currStep}
                      translate={this.translate}
                    />
                  </div>
                </div>
              </div>
            </main>
          </div>
        )}
      </div>
    );
  }
}

// Redux
const mapStateToProps = (state) => {
  return {
    formdata: state.formdata.naw,
  };
};

export default connect(
  mapStateToProps,
  {}
)(PageVerify);
