import React from 'react';

import Sticky from 'react-sticky-el';

import Navigation from '../components/Navigation';
import FormMotivation from '../components/forms/FormMotivation'
import StepContent from '../components/StepContent';
import Header from '../components/Header';
import Page from './Page';

class PageList extends Page {

    render() {

        let {mobile, stemtool, steps, history} = this.props;
        let {currStep} = steps;


        /******************** MOBILE VERSION *****************************/
        return (
            <div className="container">
                {/* Top panel */}
                <header className="header-container col col--100 clear">
                    <Header stemtool={stemtool}/>
                </header>
                {/******************** MOBILE VERSION *****************************/}
                {mobile && <div className='col col--100 mobile--container clear'>

                    <div className="padding--mobile-block--bottom">
                        {/* Info panel */}
                        <div className="padding--md margin--bottom panel--gray">
                            <StepContent currStep={currStep} translate={this.translate} />
                        </div>
                        <div className="margin">
                            <FormMotivation stemtool={stemtool} currStep={currStep} page={this}/>
                        </div>
                    </div>

                    <div className="container navigation-container--mobile">
                        <Navigation slug={stemtool.slug} steps={steps} history={history} mobile={mobile} translate={this.translate} />
                    </div>
                </div>}
                {/******************** DESKTOP VERSION *****************************/}
                {!mobile && <main className='flex col--100'>
                    {/* Info panel */}
                    <aside className="col col--40 sidebar clear">
                        <div className='sticky--container panel--gray'>
                            <Sticky mode="top" positionRecheckInterval={1} >
                                <div className='padding'>
                                    <StepContent currStep={currStep} translate={this.translate} />
                                </div>
                            </Sticky>
                        </div>
                    </aside>

                    {/* Form panel */}
                    <div className="col col--60 clear">
                        <div className='panel--white padding'>

                            <FormMotivation stemtool={stemtool} currStep={currStep} page={this} />

                            <Navigation slug={stemtool.slug} steps={steps} history={history} mobile={mobile} translate={this.translate} />

                        </div>
                    </div>

                </main>}
            </div>
        );
    }

}

export default PageList;
