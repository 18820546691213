import React, { Component } from 'react';
import { connect } from 'react-redux';

import { toggleShowShortlits } from  '../redux/actions/StemtoolAction';

class FreechoiceHeader extends Component {


    toggle = () => {

        this.props.toggleShowShortlits();
        // Cancel out scrolling on body when shortlist is visible

    }
    
    render() {

        let {translate} = this.props;
        
        if(this.props.formstates.length === 0){
            return null;
        }
                
        let {list, currStep } = this.props;

        // Count the free choice items
        var list_length = 0;
        list.forEach( (item) => { if(item._id === '0') list_length++; } );

        if(list_length === 0){
            return null;
        }

        // When we have too much records in our shortlist
        let tooMuch = ( list_length > currStep.max_free_choice ) ;
                        
        /**
         * Desktop
         */
        return (
            <div className='shortlistheader flex flex--align--vert margin--bottom'>
                <div className="col col--60 clear">
                    <div className='font-size color--white shortlist--title'>
                        <p>{ list_length > 0 ? list_length : 'Je'} vrije keuze{list_length === 1 ? '' : 's'}</p>
                    </div>
                </div>

                <div className="col col--40 clear">
                <div className='font-size--sm color--suvagray t--right shortlist--count'>
                    <p className={tooMuch ? 'color--red font-weight--bold' : ''}> 
                        ({translate('max')} {currStep.max_free_choice}) 
                    </p>
                    </div>
                </div>
            </div>            
        )

    }    
}

const mapStateToProps = (state) => {
    
    return {
        list: state.shortlist.list,

        formstates: state.stemtool.formstates
    }   
    
}


export default connect( mapStateToProps, {toggleShowShortlits} )(FreechoiceHeader);
