export const SET_SEED = 'STEMTOOL/SET_SEED';
export const FETCH_FLOW = 'STEMTOOL/FETCH_FLOW';
export const SET_STEP_VALID = 'STEMTOOL/SET_STEP_VALID';
export const SET_STEP_ENABLED = 'STEMTOOL/SET_STEP_ENABLED';
export const TOGGLE_HIGHLIGHT = 'STEMTOOL/TOGGLE_HIGHLIGHT';
export const SET_SESSION_HASH = 'STEMTOOL/SET_SESSION_HASH';
export const SET_SHARE_HASH = 'STEMTOOL/SET_SHARE_HASH';
export const SET_STEPS_SAVED = 'STEMTOOL/SET_STEPS_SAVED';
export const SET_RESET_FLAG = 'STEMTOOL/SET_RESET_FLAG';
export const TOGGLE_SHOW_SHORTLIST = 'STEMTOOL/TOGGLE_SHOW_SHORTLIST';
export const SET_TOKEN = 'STEMTOOL/SET_TOKEN';
