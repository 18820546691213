import * as type from '../types/StemtoolType';
import * as formtype from '../types/FormType';
import * as globaltype from '../types/GlobalType';
import axios from 'axios';
import elasticsearch from 'elasticsearch';
import { captureException } from '@sentry/react';

export const setToken = (token)  => (dispatch, getState) => {
    dispatch({
        type: type.SET_TOKEN,
        payload: token
    });

}

export const setSessionHash = (sessionHash)  => (dispatch, getState) => {
    dispatch({
        type: type.SET_SESSION_HASH,
        payload: sessionHash
    });
}

export const setShareHash = (shareHash)  => (dispatch, getState) => {
    dispatch({
        type: type.SET_SHARE_HASH,
        payload: shareHash
    });
}

/**
 * This function creates a random no which is used when
 * the elastic search random calls are made. It is saved in the
 * store so elastic search will get always the same seedm which is important!
 */
export const setElasticSearchSeed = () => (dispatch, getState) => {

    // Dunno... each reload a new seed or remember the seed...
    if(getState().stemtool.seed) return;

    dispatch({
        type: type.SET_SEED,
        payload: 'seed_' + Math.floor( Math.random() * 255 )
    });

};

// Gets all stepdata and shit
export const getFlow = (slug, callback = null) => (dispatch, getState) => {
    /**
     * On the servers there is an ALIAS pointing to ES
     */
    var EsHost = null;
    if( 'http' !== process.env.REACT_APP_ELASTICSEARCH_ENDPOINT_FORM.substring(0,4) ) {
        EsHost = {
            protocol: document.location.protocol,
            host: document.location.hostname,
            port: document.location.port ? document.location.port : (document.location.protocol === 'http:' ? 80 : 443),
            path: process.env.REACT_APP_ELASTICSEARCH_ENDPOINT_FORM,
            headers: {
                'content-type': 'application/json'
            }
        };
    } else {
        var urlParser = document.createElement('a');
        urlParser.href = process.env.REACT_APP_ELASTICSEARCH_ENDPOINT_FORM;

        EsHost = {
            protocol: urlParser.protocol,
            host: urlParser.hostname,
            port: urlParser.port ? urlParser.port : (urlParser.protocol === 'http:' ? 80 : 443),
            path: urlParser.pathname.substr(0, 1) === '/' ? urlParser.pathname : '/' + urlParser.pathname,
            headers: {
                'content-type': 'application/json'
            }
        };
    }
    const form = new elasticsearch.Client({ host: EsHost });

    form.search({
        body: {
            query: {
                match: {
                    slug: slug
                }
            }
        }
    }).then(({hits}) => {
        // Now init the stepStates
        let properties = hits.hits[0]._source;
        let {steps} = properties;
        let formstates = steps.map ((step, i)=>{
            return {
                enabled: i === 0 ? true : false, // First step by default enabled
                // skipable: step.skipable ? true : false, //
                valid: step.skipable ? true : false, // A step is by default valid when its skipable
                saved: false
            }
        });

        dispatch({
            type: type.FETCH_FLOW,
            payload: {
                properties: properties,
                formstates: formstates
            }
        });

        dispatch({
            type: formtype.INIT_ENQUETE,
            payload: steps
        });

        if(callback){
            callback();
        }
    });

};

export const setResetFlag = () => (dispatch) => {
    dispatch({
        type: type.SET_RESET_FLAG,
        payload: true
    });
};

export const resetApplication = () => (dispatch) => {
    dispatch({
        type: globaltype.APPLICATION_RESET
    });

};

export const saveData = (callback) => (dispatch, getStore) => {

    let store = getStore()

    let data = {
        shortlist: store.shortlist.list,
        form_naw: store.formdata.naw.values,
        session_hash: store.stemtool.session_hash,
        share_hash: store.stemtool.share_hash,
        form_enquete: store.formdata.enquete.values,
        recaptcha: store.formdata.recaptcha,
        _token: store.stemtool._token
    }

    let {slug} = store.stemtool.properties;

    axios.request(process.env.REACT_APP_BACKEND_PROTOCOL_AND_URL + 'api/form/' + slug, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        data : JSON.stringify(data)
    })
    .then(function (response) {
        if(response.data.msg === 'ok'){
            dispatch({
                type: type.SET_SESSION_HASH,
                payload: response.data.session_hash
            });
            dispatch({
                type: type.SET_SHARE_HASH,
                payload: response.data.share_hash
            });
            // And talk back the msg
            callback('ok');
        } else {
            // Just talk back what the server responded
            // Nice for debugging
            callback(response.data.msg);
        }
    })
    .catch(function (error) {
        callback('fail', error)
        captureException(error);
    });

};

export const setStepEnabled = (step) => (dispatch, getStore) => {
    // Prevent too much dispatches
    let {formstates} = getStore().stemtool;
    if(formstates[step.stepIndex].enabled)
        return;

    dispatch({
        type: type.SET_STEP_ENABLED,
        payload: step.stepIndex
    });
};

export const setStepsSaved = (currStep) => dispatch => {

    dispatch({
        type: type.SET_STEPS_SAVED,
        payload: currStep.stepIndex
    });
};

export const toggleHighlight = (song = null) => dispatch => {
    dispatch({
        type: type.TOGGLE_HIGHLIGHT,
        payload: song
    });
}

export const toggleShowShortlits = () => dispatch => {
    dispatch({
        type: type.TOGGLE_SHOW_SHORTLIST
    });
}
