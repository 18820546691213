import React, { Component } from 'react';
import { connect } from 'react-redux';

import { removeSongFromShortlistByIndex, setValid } from  '../redux/actions/ShortlistAction';
import { unsetSelectedBySong } from  '../redux/actions/LonglistAction';
import { toggleHighlight } from  '../redux/actions/StemtoolAction';
import Song  from './Song';
import Player from "./Player";



class Shortlist extends Component {
    _player = null; // ref for the player

    constructor(props) {
        super(props);

        this.props.setValid(this.props.currStep);
    }

    onSongDelete(index){
        let {list} = this.props;

        let _id = list[index]._id;

        this.props.removeSongFromShortlistByIndex(index, this.props.currStep);

        if(_id !== '0'){
            this.props.unsetSelectedBySong(list[index]);
        }

    }

    onSongHighlight ( index ) {

    }

    getSongs(list, currStep) {

        let songs = list.map ( (song,index) => {

            if (
                song._id !== '0'
                || currStep.type === 'thankyou'
                || (
                    currStep.freeChoiceStrategy === 'include_in_max'
                    && (
                        (song._id !== '0' && currStep.max_free_choice !== currStep.max)
                        ||
                        currStep.max_free_choice === currStep.max
                    )
                )
            ){

                return (
                    <li key={index}>
                        <Song
                            index={index}
                            song={song}
                            onDelete={this.onSongDelete.bind(this)}
                            currStep={this.props.currStep}
                            onSongClick={this.onSongHighlight.bind(this)}
                            mobile={this.props.mobile}
                            type={this.props.buttType}
                            parent={this}
                        />
                    </li>
                )
             } else {
                 return null;
             }
        });

        // Reverse list when on longlist-page
        if (this.props.buttType === "delete") {
            songs = songs.reverse();    
        }

        return songs;

    }

    render() {
        let {list, mobile, mobile_show_shortlist, currStep} = this.props;

        // When mobile the list is hidden when mobile_show_shortlist is false
        // When not mobile it's always visible

        if( !mobile || mobile_show_shortlist ){
            return (
                <div>
                    <Player ref={ (player) => {this._player = player}} />

                    <ul className='list--shortlist liststyle--none'>
                        { this.getSongs(list, currStep) }
                    </ul>
                </div>
            )
        }

        return null;


    }
}

const mapStateToProps = (state) => {

    return {
        list: state.shortlist.list,

        mobile_show_shortlist: state.stemtool.mobile_show_shortlist
    }

}


export default connect( mapStateToProps, {
        setValid,
        removeSongFromShortlistByIndex,
        unsetSelectedBySong,
        toggleHighlight
    })(Shortlist);
