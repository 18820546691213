import * as type from '../types/StemtoolType';

const defaultState = {
    properties: null,
    highlight : null,
    formstates : [],
    seed: null,             // Seed for the elastic search random_score setting
    session_hash: null,       // After first save get from PHP
    share_hash: null,       // After first save get from PHP
    reset_stemtool: false,
    mobile_show_shortlist: false,
    _token: null
};

export default function (state = defaultState, action) {
    switch (action.type) {
        case type.SET_TOKEN:
            return {
                ...state,
                _token: action.payload
            }

        case type.TOGGLE_SHOW_SHORTLIST:
            return {
                ...state,
                mobile_show_shortlist: !state.mobile_show_shortlist
            }
        case type.SET_RESET_FLAG:
            return {
                ...state,
                reset_stemtool: action.payload
            }
        case type.SET_STEPS_SAVED:
            // Set the payload step and all previous steps to saved
			let {formstates} = state;
            for(var i = 0; i <= action.payload; i++){
                formstates[i].saved = true;
            }
            for (i = action.payload + 1; i< formstates.length; i++){
                formstates[i].saved = false;
            }
            return {
                ...state,
                formstates: formstates
            }
        case type.SET_SESSION_HASH:
            return {
                ...state,
                session_hash: action.payload
            }
        case type.SET_SHARE_HASH:
            return {
                ...state,
                share_hash: action.payload
            }
        case type.SET_SEED:
            return {
                ...state,
                seed: action.payload
            }
        case type.SET_STEP_VALID:
			let newSetStepValidFormstates = state.formstates.slice(0);
            let {stepIndex} = action.payload;
            let {valid} = action.payload;

            newSetStepValidFormstates[stepIndex].valid = valid;
            // I think this might be the wrong place for this code..
            // When THIS step is not valid set all the next steps enabled to false
            // So we can not go there by manipulating the URL
            if(!valid){
                for(i = stepIndex + 1; i< newSetStepValidFormstates.length; i++){
                    newSetStepValidFormstates[i].enabled = false;
                }
            }

            return {
                ...state,
                formstates: newSetStepValidFormstates
            }
        case type.SET_STEP_ENABLED:
			let newSetStepEnabledFormstates = state.formstates.slice(0);
            // set the
            newSetStepEnabledFormstates[action.payload].enabled = true;

            for (i = action.payload + 1; i< newSetStepEnabledFormstates.length; i++) {
                newSetStepEnabledFormstates[i].enabled = false;
            }

            return {
                ...state,
                formstates: newSetStepEnabledFormstates
            }
        case type.FETCH_FLOW:

            if (state.properties !== null && state.properties.id !== action.payload.properties.id) {
                // Clear localStorage, stemtool IDs do not match
                document.location = '/' + action.payload.properties.slug + '/clear';
            }

            if (state.formstates.length > 0) {
                action.payload.formstates = state.formstates;
            }

            return {
                ...state,
                properties: action.payload.properties,
                formstates: action.payload.formstates
            }

        case type.TOGGLE_HIGHLIGHT:

            return {
                ...state,
                highlight: action.payload
            }

        default:
            return state;


    }
}
