import React, { Component } from 'react';
import { connect } from 'react-redux';
import Analytics from '../../model/Analytics';

class InstagramStoriesImage extends Component {
    /* eslint-disable no-unused-vars */

    constructor(props) {
        super();

        this.state = {
            canvas: null
        };

        this.submissionData = {
            shortlist: []
        };
    }

    componentDidMount() {
        this.getSubmissionData().then(() => {
            this.buildImage();
        });
    }

    getSubmissionData() {
        let imagePromises = [];
        let list = [];

        if (this.props.shortlist !== undefined) {
            list = this.props.shortlist;
        } else {
            list = this.props.list;
        }

        return new Promise((resolveFunction, rejectFunction) => {
            list.forEach(item => {
                if (item._source.imageBig) {
                    let itemOutput = {
                        artist: item._source.artist,
                        title: item._source.title,
                        image: this.getImage('https://cors-proxy.nporadio.nl/' + item._source.imageBig.replace('stem-backend-local.npo.nl', 'stem-backend.npo.nl'))
                    };

                    imagePromises.push(itemOutput.image);
                    this.submissionData.shortlist.push(itemOutput);
                }
            });

            if (imagePromises.length < 12) {
                list.forEach(item => {
                    if (!item._source.imageBig) {
                        let itemOutput = {
                            artist: item._source.artist,
                            title: item._source.title,
                            image: this.generateFreechoiceImage(item._source.artist, item._source.title)
                        };
    
                        imagePromises.push(itemOutput.image);
                        this.submissionData.shortlist.push(itemOutput);
                    }
                });
            }

            Promise.all(imagePromises).then(items => {
                // Merge resolved promises back into array
                this.submissionData.shortlist = this.submissionData.shortlist.map((item, i) => {
                    item.image = items[i];

                    return item;
                });

                resolveFunction();
            });
        });
    }

    getImage(url) {
        return new Promise((resolve, reject) => {
            var image = new Image();
            image.onload = function() {
                resolve(this);
            };
            image.onerror = function(e) {
                console.error(e);
                reject();
            }
            image.crossOrigin = 'anonymous';
            image.src = url;
        });
    }

    generateFreechoiceImage(artist, title) {
        return new Promise((functionResolve, functionReject) => {

            var canvas = document.createElement("canvas");
            canvas.width = 1000;
            canvas.height = 1000;
            var ctx = canvas.getContext("2d");


            var image = new Image();
            image.onload = function() {
                ctx.drawImage(image, 0, 0, 1000, 1000);

                // Add text to image
                ctx.fillStyle = '#ffffff';
                ctx.textAlign = 'center';
                ctx.font = 'bold 72px Arial';
                ctx.fillText((artist.length > 25 ? artist.substr(0, 20) + ' ..' : artist), 500, 150);

                ctx.font = '75px Arial';

                if (title.length > 25) {
                    let titleLines = title.match(/.{25}\w*|.*/g);

                    ctx.fillText(titleLines[0], 500, 850);
                    ctx.fillText((titleLines[2] !== undefined && titleLines[2].length > 0 ? titleLines[1].substr(0, 20) + ' ..' : titleLines[1]), 500, 950);
                } else {
                    ctx.fillText(title, 500, 900);
                }
                
                functionResolve(canvas);
            };
            image.onerror = function(e) {
                console.error(e);
                functionReject();
            }
            image.crossOrigin = 'anonymous';
            image.src = '/static/songcover-placeholder.svg';
        });
    }

    buildImage() {
        var canvas = document.createElement("canvas");
        canvas.width = 1080;
        canvas.height = 1920;
        var ctx = canvas.getContext("2d");

        this.getImage('https://cors-proxy.nporadio.nl/' + this.props.stemtool.content.instagramStoriesImage.replace('stem-backend-local.npo.nl', 'stem-backend.npo.nl')).then((image) => {
            ctx.drawImage(image, 0, 0, 1080, 1920);

            if (this.submissionData.shortlist.length === 1) {
                ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
                ctx.shadowBlur = 22;
                ctx.drawImage(this.submissionData.shortlist[0].image, 125, 585, 830, 830);
                
            } else {

                // Album cover positions
                let coverPositions = [
                    [125, 585],
                    [403, 585],
                    [681, 585],
                    [125, 863],
                    [403, 863],
                    [681, 863],
                    [125, 1141],
                    [403, 1141],
                    [681, 1141],
                    [125, 1419],
                    [403, 1419],
                    [681, 1419],
                ];

                // Add album covers
                shuffle(this.submissionData.shortlist).forEach((item, i) => {
                    if (coverPositions[i] !== undefined) {
                        ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
                        ctx.shadowBlur = 22;
                        ctx.drawImage(item.image, coverPositions[i][0], coverPositions[i][1], 278, 278);
                    }
                });

            }

            this.setState({
                canvas
            });
        });
    }

    paintImage() {
        // Export naar PNG data-url
        return this.state.canvas.toDataURL("image/png");
    }

    canShareToInstagram() {
        return (
            navigator.share !== undefined
            && navigator.userAgent.toLowerCase().indexOf('android') > -1
        );
    }

    share() {
        this.state.canvas.toBlob(blob => {
            const file = new File([blob], this.props.stemtool.slug + '-instagram-stories.png', { type: blob.type });
            const data = {
              title: this.props.stemtool.content.title,
              text: this.props.stemtool.content.og_description,
              files: [file],
            };

            navigator.share(data);
        }, 'image/png');

        Analytics.click('download', 'share-instagram-stories-share');
    }

    download() {
        Analytics.click('download', 'share-instagram-stories-download');

        var link = document.createElement('a');
        link.download = this.props.stemtool.slug + '-instagram-stories.png';
        link.href = this.paintImage();
        link.click();
    }

    render() {
        let {translate} = this.props;

        return (
            <div style={{ position: 'relative' }}>
                {this.state.canvas ?
                    this.canShareToInstagram()
                        ? <a className="btn btn--blue" onClick={this.share.bind(this)}>{translate('Deel afbeelding')}</a>
                        : <a className="btn btn--blue" onClick={this.download.bind(this)}>{translate('Download afbeelding')}</a>
                    : null
                }

                {this.state.canvas
                    ? <img src={this.paintImage()} style={{'width': '100%', paddingTop: '10px'}} alt="" />
                    : <div style={{ height: 0, paddingTop: (1920/1080 * 100) + '%', overflow: 'hidden' }}>
                        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: '#cccccc', 'textAlign': 'center', 'color': '#444444' }}>
                            {translate('Je afbeelding wordt gebouwd...')}
                        </div>
                      </div>
                }
            </div>
        );
    }
}

const shuffle = (a) => {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

const mapStateToProps = (state) => {
    return {
        list: state.shortlist.list,
    };
}

export default connect( mapStateToProps, {})(InstagramStoriesImage);
