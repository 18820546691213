import React, { Component } from 'react';

class Player extends Component {

    playThis = (url) => {

        // When we get the same url the second tine we stop
        if(this._source.src === url){
            var action = 'stop';
        } else {
            action = 'start';
        }
        // Set song
        this._source.src = url;
               
        if(action === 'start'){  
            var self = this;
            
            // Play            
            var volume = this._audio.volume = 0;  
            
            this._audio.load(); 
            this._audio.play(); 
            // Fade in
            var tid = setInterval(function(){                    
                volume = volume + 0.05;
                if(volume > 1.0){
                    volume = 1.0;
                }                                        
                if(volume >= 1.0){                    
                    clearInterval(tid);
                }                                    
                self._audio.volume = volume;
            }, 50);

            return true;
        } else {   
            // Stop                
            this._audio.pause();
            this._audio.currentTime = 0;                                
            this._source.src = ''; // you need this when toggling the same song. So this._source.src !== url will generate a 'start'
            return false;
        }        
    }


    render(){
        
        return (
                <audio ref={(audio)=>{this._audio = audio}} id="stemtool-player">
                    <source ref={(source)=>{this._source = source}} id="audioSource" src="" type="audio/mpeg" />        
                </audio>
            );
        }
    }
export default Player
