import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setFilter,setFilterField } from '../../redux/actions/LonglistAction';

class Filter extends Component {
    value = null;
    field = null;

    isChanged = false;

    constructor(props){
        super();
    }

    componentDidMount(){
        // Copy the current redux data into the state (initial load, you need this!)
        this.setState({
            field: this.props.filter.field,
            value: this.props.filter.value
        });
    }

    filterClicked(e) {

        let clickedFilterVal = e.target.dataset.filter;

        if(clickedFilterVal === this.state.value){
            clickedFilterVal = 'random';
        }

        // Letters a b c
        this.setState({value : clickedFilterVal}, () => {
            // When we're in desktop version immediately apply
            if(!this.props.modal) {
                this.apply();
            } else {
                // On mobile, remember there was a change
                this.isChanged = true;
            }
        });

    }
    filterFieldChanged(e) {
        // Type artist or title
        this.setState({field : e.target.options[e.target.selectedIndex].value}, () => {
            // When we're in desktop version immediately apply
            if(!this.props.modal) {
                this.apply();
            } else {
                // On mobile, remember there was a change
                this.isChanged = true;
            }

        });
    }

    apply(){

        this.props.setFilter(this.state.value, this.props.currStep);
        this.props.setFilterField(this.state.field, this.props.currStep);
        if(this.props.modal){
            this.props.modal.handleCloseModal();
        }

        // Scroll to top when page changes, but only when the user is below the header
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        var headerHeight = document.getElementsByClassName('media__header')[0].offsetHeight;
        if(scrollTop > headerHeight) {
            window.scrollTo(0, headerHeight);
        }
    }

    cancel(){

        // cancel only in mobile version anyway
        this.setState({value : 'random'}, function(){
            this.apply();
        });
    }

    close(){

        // only in mobile version, apply when a change was made, otherwise just close
        if(this.isChanged) {
            this.apply();
        }
        this.isChanged = false;

        this.props.modal.handleCloseModal();

        
    }
    
    render() {

        let {translate} = this.props;

        if(!this.state){
            return null;
        }

        let cls = "button color--darkgray filter t--center font-size--sm";
        return (
            <div>
                <div className="clear"></div>
                <div className="margin--top--standard">
                    <div className="flex--container filter--container">

                        <button className={`${ this.state.value === 'all' ? 'active ' : ''}button--width-lg ${cls}`} onClick={this.filterClicked.bind(this)} type="button" data-filter="all">A-Z</button>

                        {'abcdefghijklmnopqrstuvwxyz'.split('').map((i) => {
                            return <button key={i} className={`${ this.state.value === i ? 'active ' : ''}button--width-md ${cls}`} onClick={this.filterClicked.bind(this)} type="button" data-filter={i}>{i.toUpperCase()}</button>
                        })}

                        <button className={`${ this.state.value === '0-9' ? 'active ' : ''}button--width-lg ${cls}`} onClick={this.filterClicked.bind(this)} type="button" data-filter="0-9">0-9</button>

                    </div>
                    <div className="margin--top--sm search--container">
                        <div className="col col--40 clear">
                            <span className="color--lightgray" style={{float:'right', 'margin': '4px 10px 0 0'}}>{translate('Filter op')}:</span>
                        </div>
                        <div className="col col--40 clear">
                            <div className="margin--right--xsm" style={{'width':'100%'}} >
                                <select value={this.state.field} id="sort-by" className="search--item text-transform--uppercase color--labelgray col col--100" onChange={this.filterFieldChanged.bind(this)}>
                                    <option value="artist">{translate('Artiest')}</option>
                                    <option value="title">{translate('Titel')}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.mobile &&
                (<div className="margin--top">
                    <button type="submit" className="btn btn--blue" onClick={this.apply.bind(this)} >
                        {translate('Filter')}
                    </button>
                    <button type="button" className="btn btn--gray" onClick={this.cancel.bind(this)} style={{'marginTop':'1.25em'}}>
                        {translate('Wis filter')}
                    </button>
                    <div className="btn" style={{'position':'absolute', 'top':'0', 'right':'0', 'left':'0', 'borderRadius':'0', 'cursor':'initial', 'backgroundColor':'black', 'color':'white', 'lineHeight':'40px', 'textAlign':'center', 'fontSize':'16px'}} >
                        <button type="submit" onClick={this.close.bind(this)} style={{'position':'absolute', 'top':'0', 'left':'0', 'height':'40px', 'width':'40px', 'cursor':'pointer', 'fontSize':'16px'}} >
                            <svg width="40pt" height="40pt" fill="white" version="1.1" viewBox="0 0 100 100" style={{'margin':'-10px 0 0 -17px'}} xmlns="http://www.w3.org/2000/svg">
                                <path d="m50 48.852-15.711-15.719c-1.0898-1.0508-2.8242-1.0391-3.8945 0.035157-1.0703 1.0703-1.0859 2.8047-0.035156 3.8945l15.707 15.715-15.715 15.711c-1.0859 1.0859-1.0859 2.8477 0 3.9336 1.0859 1.0859 2.8438 1.0859 3.9336 0l15.715-15.715 15.711 15.715c1.0859 1.0859 2.8477 1.0859 3.9336 0 1.0859-1.0859 1.0859-2.8477 0-3.9336l-15.715-15.711 15.715-15.711c1.0508-1.0898 1.0391-2.8203-0.035156-3.8945-1.0703-1.0703-2.8047-1.0859-3.8945-0.03125l-15.715 15.703z"/>
                            </svg>
                        </button>
                        <div style={{'width':'100%'}} >
                            {translate('Filteren')}
                        </div>
                    </div>
                </div>)
                }

            </div>

        )

    }
}
const mapStateToProps = (state) => {
    return {
        filter: state.longlist.filter
    }
}
export default connect(mapStateToProps, {setFilter,setFilterField})(Filter);

