import React, { Component } from 'react';
import ReactModal from 'react-modal';
import Modal from 'react-modal';
import InstagramStoriesImage from './imagegenerators/InstagramStoriesImage';
import InstagramFeedImage from './imagegenerators/InstagramFeedImage';
import Analytics from '../model/Analytics';

const myStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: "6000"
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        minWidth: '320px',
        maxWidth: '100vw',
        width: '50vw',
        padding: "40px 0px 0px",
        border: 0
    }
}

class ModalShareToInstagram extends Component {

    constructor(props) {
        super();

        props.init(this, 'ModalShareToInstagram');

        Modal.setAppElement('#root');

        this.state = {
            showModal: !!props.opened
        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);

        if (!!props.opened) {
            Analytics.click('navigation', 'share-instagram-modal');
        }
    }

    handleOpenModal() {
        this.setState({ showModal: true });

        Analytics.click('navigation', 'share-instagram-modal');
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }

    render() {
        let {translate, stemtool, mobile, shortlist} = this.props;

        return (
            <div>

                <ReactModal isOpen={this.state.showModal} contentLabel="Deel op Instagram" style={myStyle}>
                    <div className="btn" style={{'position':'absolute', 'top':'0', 'right':'0', 'left':'0', 'cursor':'initial', 'backgroundColor':'black', 'color':'white', 'lineHeight':'40px', 'textAlign':'center', 'fontSize':'16px'}} >
                        <button type="submit" onClick={this.handleCloseModal.bind(this)} style={{'position':'absolute', 'top':'0', 'left':'0', 'height':'40px', 'width':'40px', 'padding': '0', 'border': '0', 'cursor':'pointer'}} >
                            <svg width="40" height="40" fill="white" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <path d="m50 48.852-15.711-15.719c-1.0898-1.0508-2.8242-1.0391-3.8945 0.035157-1.0703 1.0703-1.0859 2.8047-0.035156 3.8945l15.707 15.715-15.715 15.711c-1.0859 1.0859-1.0859 2.8477 0 3.9336 1.0859 1.0859 2.8438 1.0859 3.9336 0l15.715-15.715 15.711 15.715c1.0859 1.0859 2.8477 1.0859 3.9336 0 1.0859-1.0859 1.0859-2.8477 0-3.9336l-15.715-15.711 15.715-15.711c1.0508-1.0898 1.0391-2.8203-0.035156-3.8945-1.0703-1.0703-2.8047-1.0859-3.8945-0.03125l-15.715 15.703z"/>
                            </svg>
                        </button>
                        <div style={{'width':'100%'}} >
                            {translate('Deel op Instagram')}
                        </div>
                    </div>

                    <div className="modal-innerscroll">

                        <div className="margin--bottom">
                            {translate('Download hieronder een vierkante of rechthoekige afbeelding voor in je instafeed of instastories.')}
                        </div>

                        <div className={mobile ? null : "flex"}>
                            <div className={mobile ? "col col--100 margin--bottom" : "col col--40 padding--md--right"}>
                                <div className="font-size--md font-weight--bold padding--sm--bottom">{translate('Instagram stories')}</div>
                                <InstagramStoriesImage stemtool={stemtool} shortlist={shortlist} translate={translate} />
                            </div>
                            <div className={mobile ? "col col--100 margin--bottom" : "col col--60 padding--md--left"}>
                                <div className="font-size--md font-weight--bold padding--sm--bottom">{translate('Instagram feed')}</div>
                                <InstagramFeedImage stemtool={stemtool} shortlist={shortlist} translate={translate} />
                            </div>
                        </div>

                    </div>
                    
                    
                </ReactModal>
            </div>
        );
    }
}

export default ModalShareToInstagram
