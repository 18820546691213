import React, { Component } from 'react';
import { connect } from 'react-redux';

import { removeSongFromShortlistByIndex } from  '../redux/actions/ShortlistAction';
import { unsetSelectedBySong } from  '../redux/actions/LonglistAction'; 

import Song  from './Song';



class Shortlist extends Component {

    freechoiceCount = 0;
    onSongDelete(index){
        let {list} = this.props;
        
        this.props.removeSongFromShortlistByIndex(index, this.props.currStep);
        this.props.unsetSelectedBySong(list[index]);
    }

    getSongs(list){
        var count = 0;
        let songs = list.map ( (song,index) => {
            if(song._id === '0'){
                count++;
                return (
                    <li key={index}>
                        <Song 
                            index={index} 
                            song={song} 
                            onDelete={this.onSongDelete.bind(this)} 
                            currStep={this.props.currStep} 
                            type={this.props.buttType}
                        />
                    </li>
                )            
            } else {
                return null;
            }
        });
        this.freechoiceCount = count;
        return songs;
        
    }
    
    render() {
        let {list, mobile, mobile_show_shortlist, currStep} = this.props;

        // When mobile the list is hidden when mobile_show_shortlist is false
        // When not mobile it's always visible
        var songs = this.getSongs(list)
        
        
        if( !mobile || mobile_show_shortlist ){
            return (            
                <ul className='list--shortlist liststyle--none'>
                    {/* On mobile the freechoice hearder is just info (does not have functionality) */}
                    { mobile && this.freechoiceCount > 0 && 
                        (<div className="shortlist-freechoice--mobile font-size--sm color--suvagray">
                                { this.freechoiceCount > 0 ? this.freechoiceCount : 'Je'} vrije keuze{this.freechoiceCount === 1 ? '' : 's'}
                                <span className={`font-size--sm color--suvagray ${this.freechoiceCount > currStep.max_free_choice  ? 'color--red font-weight--bold' : ''}`}> (MAX {currStep.max_free_choice}) </span>                                
                        </div>)
                    }
                    { songs }
                    <div style={{height:'35px'}}></div>
                </ul>                
            )
        }
        
        return null;
        
                           
    }    
}

const mapStateToProps = (state) => {
    
    return {
        list: state.shortlist.list,
        mobile_show_shortlist: state.stemtool.mobile_show_shortlist
    }
    
}


export default connect( mapStateToProps, { 
        removeSongFromShortlistByIndex,
        unsetSelectedBySong 
    })(Shortlist);
