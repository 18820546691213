import { combineReducers } from 'redux';

import stemtoolReducer from './StemtoolReducer';
import longlistReducer from './LonglistReducer';
import shortlistReducer from './ShortlistReducer';
import formReducer from './FormReducer';
import * as type from '../types/GlobalType';

const appReducer = combineReducers({
   
   stemtool: stemtoolReducer,
   longlist: longlistReducer,
   shortlist: shortlistReducer,
   formdata: formReducer

});

const rootReducer = (state, action) => {

    if (action.type === type.APPLICATION_RESET) {
        
        // Object.keys(state) will give you all redux stores
        
        // Destroy the redux-persist data
        localStorage.removeItem("persist:root");
        
        // Set state to undefined. This should reset the redux stores to their defaults
        state = undefined;

    }
    
    return appReducer(state, action)
}

export default rootReducer
