import * as type from '../types/LonglistType';

import bodybuilder from 'bodybuilder';
import elasticsearch from 'elasticsearch';

/**
 * 
 * @param string slug
 * @param integer listIndex 
 * @param boolean clear
 * 
 */

var prev_search = '';
var prev_filter = '';
var prev_sort = '';
var current_list_length = 0;
export const getLonglist = (slug, listIndex, clear = false) => (dispatch, getStore) => {

    /**
     * On the servers there is an ALIAS pointing to ES
     */
    var EsHost = null;
    if( 'http' !== process.env.REACT_APP_ELASTICSEARCH_ENDPOINT_LIST.substring(0,4) ) {
        EsHost = {
            protocol: document.location.protocol,
            host: document.location.hostname,
            port: document.location.port ? document.location.port : (document.location.protocol === 'http:' ? 80 : 443),
            path: process.env.REACT_APP_ELASTICSEARCH_ENDPOINT_LIST,
            headers: {
                'content-type': 'application/json'
            }
        };
    } else {
        var urlParser = document.createElement('a');
        urlParser.href = process.env.REACT_APP_ELASTICSEARCH_ENDPOINT_LIST;

        EsHost = {
            protocol: urlParser.protocol,
            host: urlParser.hostname,
            port: urlParser.port ? urlParser.port : (urlParser.protocol === 'http:' ? 80 : 443),
            path: urlParser.pathname.substr(0, 1) === '/' ? urlParser.pathname : '/' + urlParser.pathname,
            headers: {
                'content-type': 'application/json'
            }
        };
    }

    const es = new elasticsearch.Client({ host: EsHost });

    // need the list    
    let {settings, filter, search} = getStore().longlist;
    // need some settings
    let {limit} = settings;

    /**
     * prevents double data loading (hopefully)
     */
    if( (current_list_length > 0 && current_list_length < limit) &&
        prev_search === search.value &&
        prev_filter === filter.value &&
        prev_sort === filter.field
    ){
        return;
    }
    prev_search = search.value;
    prev_filter = filter.value;
    prev_sort = filter.field;
    /*******************************************/

    /**
     *  Here we calculate the next from (for the data chunk from es)
     *  We get the list index from the view.. its the song that got visible
     *  When it's more than we have already we ask for more from es
     *  otherwise we'll simply return
     *  
     *  The 'clear' is there for when we filter or search. The from 
     *  should be 0 when we apply a filter or a search (we start over)
     */
    if( !( (limit + listIndex > current_list_length) || clear ) ){
        return;
    } 
    // Calculate the next from either the end of the current list or set to 0 when clear
    var from = (listIndex === 0 || clear) ? 0 : current_list_length;

    // Set expected new list length, so we don't have to wait for list.length
    current_list_length = from + limit;

    // Create the query, starting with the required slug
    var body = bodybuilder().filter('match', 'slug', slug);

    // We need a special field for artist-sorting
    if (filter.field === 'artist') {
        filter.field = 'sortArtist';
    }

    // Which filter is selected?
    switch(filter.value){
        case 'random':
            // ! get the seed from the 'stemtool' store
            let {seed} = getStore().stemtool;

            body.query('function_score', {
              random_score: { seed: seed,  field: '_seq_no' }, // Should be cheapest consistent random field              
              boost_mode: 'sum'
            });
            break;
        case 'all':
            // Sorting is done below
            break;
        case '0-9':
            body.filter('regexp', filter.field + '.letter', '[0-9].+');
            break;
        default:
            // You end up here when you select a letter
            body.filter('prefix', filter.field + '.letter', filter.value);
            break;
    }

    // When not random, we sort alphabetically
    if (filter.value !== 'random') {
        body.sort(filter.field + '.sort', 'asc');
    }

    // Add search query
    if(search.value !== ''){

        // Filter out 'the' and 'de' when they are at the beginning of the query
        if (search.value.toLowerCase().indexOf('the ') === 0) {
            search.value = search.value.substr(4);
        }
        if (search.value.toLowerCase().indexOf('de ') === 0) {
            search.value = search.value.substr(3);
        }

        // filter out query-string operators
        search.value = search.value.replace(/[^A-Za-z0-9.' ]/g, ' ');

        // Add ~* after each word
        let parsedKeyword = search.value.replace(/([ ]+|$)/g, "* ").trim();

        body.filter('query_string', {
            fields: ['artist', 'title', 'sortArtist'],
            query: parsedKeyword,
            default_operator: 'AND'
        });
    }

    // Add the from and limit
    body.from(from);
    body.size(limit);

    let finalBody = body.build();

    // console.log(JSON.stringify(finalBody,0,2));

    /***************************************************************************
     * And make call ES
     **************************************************************************/
    es.search({ body: finalBody }).then(({hits}) => {

        if (hits.hits.length > 0 || clear) {

            dispatch({
                type: type.FETCH_LONGLIST,
                payload: { 
                    data: setSongsSelectedThatWeFindInTheShortlist(hits.hits, getStore().shortlist.list),
                    clear: clear
                }

            });

        }
    });
};

const setSongsSelectedThatWeFindInTheShortlist = (longlist, shortlist) => {
    // Make array of shortlist id's
    let _idsInShortlist = shortlist.map ((item) => { return item._id });
    // Now set the items selected that are in the shortlist
    let result = longlist.map((item) => {
        if(-1 !== _idsInShortlist.indexOf(item._id)){
            item._selected = true;
        }
        return item; 
    });

    return result;

}

export const setSelected = (index) => dispatch => {
    dispatch({
        type: type.SET_SELECTED,
        payload: index
    });        
}
export const unsetSelected = (index) => dispatch => {
    dispatch({
        type: type.UNSET_SELECTED,
        payload: index
    });        
}
export const unsetSelectedBySong = (song) => dispatch => {
    // Have to find the index
    dispatch({
        type: type.UNSET_SELECTED_BY_SONG,
        song: song
    });    
};
export const setFilterField = (value, step) => (dispatch, getStore) => {
    // This one will set the filter
    dispatch({
       type: type.SET_FILTER_FIELD,
       payload: value
    });
    // And here we get the new Longlist
    getLonglist(step.slug, 0, true)(dispatch, getStore);

}
export const setFilter = (value, step) => (dispatch, getStore) => {
    // This one will set the filter
    dispatch({
       type: type.SET_FILTER,
       payload: value
    });
    // And here we get the new Longlist
    getLonglist(step.slug, 0, true)(dispatch, getStore);

}
export const setSearchValue = (value, step) => (dispatch, getStore) => {
    // This one will set the filter
    dispatch({
       type: type.SET_SEARCH_VALUE,
       payload: value
    });

    // And here we get the new Longlist
    getLonglist(step.slug, 0, true)(dispatch, getStore);

}
