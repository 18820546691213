import React, { Component } from 'react';
import ReactModal from 'react-modal';
import Modal from 'react-modal';
import FormFreechoice from './forms/FormFreechoice';

const myStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: "6000"
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        minWidth: '320px',
        maxWidth: '100vw',
        width: '50vw',
        padding: "40px 20px 20px",
        border: 0
    }
}

class ModalFreechoice extends  Component{

    constructor (props) {
        super();

        // @todo this one seems to not work
        Modal.setAppElement('#root');

        props.init(this, 'ModalFreechoice');

        this.state = {
            showModal: false
        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleOpenModal () {
        this.setState({ showModal: true });
    }

    handleCloseModal () {
        this.setState({ showModal: false });
    }

    render () {
        
        return (
            <div>

                <ReactModal isOpen={this.state.showModal} contentLabel="Minimal Modal Example" style={myStyle}>
                    <FormFreechoice
                        currStep={this.props.currStep}
                        modal={this}
                        mobile={this.props.mobile}
                        translate={this.props.translate}
                        page={this.props.page}
                    />
                </ReactModal>
            </div>
        );
    }
}

export default ModalFreechoice


