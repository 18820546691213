import * as Sentry from "@sentry/react";

const getEnvironment = () => {
	const backendUrl = process.env.REACT_APP_BACKEND_PROTOCOL_AND_URL || '';

	if (backendUrl.includes('local')) {
		return 'development';
	} else if (backendUrl.includes('test')) {
		return 'test';
	}

	return 'production';
}

const initSentry = () => {
	Sentry.init({
		denyUrls: [
			// Facebook flakiness
			/graph\.facebook\.com/i,
			// Facebook blocked
			/connect\.facebook\.net\/en_US\/all\.js/i,
			// Chrome extensions
			/extensions\//i,
			/^chrome:\/\//i,
			/^chrome-extension:\/\//i,
		],
		dsn: "https://46fc57f56e274cf2b92affda6442a1d5@o468856.ingest.sentry.io/4505390787854336",
		environment: getEnvironment(),
		tracesSampleRate: 1.0,
	});
};

export default initSentry;
