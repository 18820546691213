import * as type from '../types/ShortlistType';
import * as stemtoolType from '../types/StemtoolType';

export const addSongToShortlist = (song, step) => (dispatch, getStore) => {
    dispatch({
        type: type.ADD_SONG_TO_SHORTLIST,
        payload: song
    });
    setValid(step)(dispatch, getStore);
};

export const removeSongFromShortlistByIndex = (index, step) => (dispatch, getStore) => {
    dispatch({
        type: type.REMOVE_SONG_FROM_SHORTLIST_BY_ID,
        payload: index
    });    
    setValid(step)(dispatch, getStore);
};
export const removeSongFromShortlistBySong = (song, step) => (dispatch, getStore) => {
    // Have to find the index
    dispatch({
        type: type.REMOVE_SONG_FROM_SHORTLIST_BY_SONG,
        song: song
    });    
    setValid(step)(dispatch, getStore);
};

export const addMotivationsToShortlist = (motivation, index) => dispatch => {
    dispatch({
        type: type.ADD_MOTIVATIONS_TO_SHORTLIST,
        payload: {
            index: index,
            motivation: motivation
        }
    });    
}

export const toggleMotivation = (index) => dispatch => {
    dispatch({
        type: type.TOGGLE_MOTIVATION_VISIBILITY,
        payload: index
    });
}

export const setShortlist = (songs) => (dispatch, getStore) => {
    dispatch({
        type: type.CLEAR_SHORTLIST
    });

    songs.forEach((song, i) => {
        dispatch({
            type: type.ADD_SONG_TO_SHORTLIST,
            payload: song
        });
    });
};

/**
 * calculate if the shortlis step is valid
 */
export const setValid = (thisStep) => (dispatch, getStore) =>  {

    // dispatch should be synchronus
    var shortlist_len = 0;
    var freechoice_len = 0;

    getStore().shortlist.list.forEach((item)=>{
        if(item._id === '0'){
            freechoice_len++;
        } else {
            shortlist_len++;
        }
    });

    // Shortlist should never be empty
    let no_zero = shortlist_len + freechoice_len !== 0;
    let valid = false;

    switch (thisStep.freeChoiceStrategy) {
        // Check for case where freechoices are counted separate from shortlist
        case 'add_to_max':
            let shortlist_valid = (shortlist_len >= thisStep.min) && (shortlist_len <= thisStep.max) ? true : false;
            let freecoice_valid = (freechoice_len <= thisStep.max_free_choice) ? true : false;
            valid = shortlist_valid && freecoice_valid && no_zero;
            break;
        case 'include_in_max':
            // Check for case where freechoices are counted inside shortlist
            let total = shortlist_len + freechoice_len;
            let total_valid = (total >= thisStep.min) && (total <= thisStep.max) && (freechoice_len <= thisStep.max_free_choice) ? true : false;
            valid = total_valid && no_zero;
            break;
        default:

    }

    dispatch({
        type: stemtoolType.SET_STEP_VALID,
        payload: {
            stepIndex: thisStep.stepIndex,
            valid: valid
        }
    });
    
};
