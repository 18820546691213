import React from 'react';
import Sticky from 'react-sticky-el';
import NotificationSystem from 'react-notification-system';
import Navigation from '../components/Navigation';
import Recaptcha from '../components/forms/Recaptcha';
import StepContent from '../components/StepContent';
import Header from '../components/Header';
import FormPersonalData from '../components/forms/FormPersonalData';
import Page from './Page';

class PageForm extends Page {


    render() {

        let {mobile, stemtool, steps, history} = this.props;
        let {currStep} = steps;

        return (
            <div className="container">

                {/* Popup */}
                <NotificationSystem ref="notificationSystem" />

                {/* Top panel */}
                <header className="header-container col col--100 clear">
                    <Header stemtool={stemtool}/>
                </header>

                {/******************** MOBILE VERSION *****************************/}
                {mobile && <div className='col col--100 mobile--container clear'>

                    <div className="padding--mobile-block--bottom">
                        {/* Info panel */}
                        <div className="padding--md margin--bottom panel--gray">
                            <StepContent currStep={currStep} translate={this.translate} />
                        </div>
                        <div className="margin">
                            <FormPersonalData stemtool={stemtool} steps={this.props.steps} translate={this.translate} />
                            <Recaptcha currStep={currStep}/>
                        </div>
                    </div>
                    <div className="container navigation-container--mobile">
                        <Navigation slug={stemtool.slug} steps={steps} history={history} mobile={mobile} page={this} translate={this.translate} />
                    </div>
                </div>}
                {/******************** DESKTOP VERSION *****************************/}
                {!mobile && <main className='flex col--100'>
                    {/* Info panel */}
                    <aside className="col col--40 sidebar clear">
                        <div className='sticky--container panel--gray'>
                            <Sticky mode="top" positionRecheckInterval={1} >
                                <div className='padding'>
                                    <StepContent currStep={currStep} translate={this.translate} />
                                </div>
                            </Sticky>
                        </div>
                    </aside>

                    {/* Form panel */}
                    <div className="col col--60 clear">
                        <div className='panel--white padding'>
                            <FormPersonalData stemtool={stemtool} steps={this.props.steps} translate={this.translate} />
                            <Recaptcha currStep={currStep}/>
                            <Navigation slug={stemtool.slug} steps={steps} history={history} mobile={mobile} page={this} translate={this.translate} />
                        </div>
                    </div>
                </main>}
            </div>
        );

    }
}

export default PageForm;
