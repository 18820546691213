import { Component } from 'react';
import Parser from 'html-react-parser';
import {store} from '../redux/store';
import Analytics from '../model/Analytics';

class Page extends Component {

    /**
     * Notifications
     */
    _notificationSystem = null;

    translatables = null;
    translate = (key) => {
        if(!this.translatables){
            return null;
        }
        if(this.translatables[key]){
            return this.translatables[key];
        } else {
            return key;
        }
    }

    _prompt = (message, level = 'warning', position = 'bc', duration = 0) => {

        this._notificationSystem.addNotification({
            message: Parser(message),
            level: level,
            position: position,
            autoDismiss: duration
        });
    }
    componentDidMount() {

        // Scroll to top when page changes, but only when the user is below the header
        // Wait a sec for the page to render
        setTimeout(function() {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            var headerHeight = document.getElementsByClassName('media__header')[0].offsetHeight;
            if(scrollTop > headerHeight) {
                window.scrollTo(0, headerHeight);
            }
        }, 100);


        this._notificationSystem = this.refs.notificationSystem;
    }

    constructor(props){

        super(props);

        // !! YOU NEED THE ONE FROM THE PROPS HERE    //
        // !! THE ONE FROM THE STORE CAN BE undefined //
        var {slug} = props.stemtool;
        var {steps} = props;
        var {currStep} = steps;
        var {online, offline, offlineForNewSubmissions} = props.stemtool;

        // Parse the JSON here cuz ES has trouble with keys with spaces in them
        this.translatables = JSON.parse(props.stemtool.translatables);

        /***********************************************************************
         *
         * When the stemtool is closed we redirect to the closedstep
         * When share or closed we should not redirect (nonflow steps)
         *
         **********************************************************************/
        if (!currStep.nonflow) {
            var now = new Date();

            var match = online.match(/^(\d+)-(\d+)-(\d+) (\d+):(\d+):(\d+)$/);
            var dateOnline = new Date(Date.UTC(match[1], match[2] - 1, match[3], match[4], match[5], match[6]));

            match = offline.match(/^(\d+)-(\d+)-(\d+) (\d+):(\d+):(\d+)$/);
            var dateOffline = new Date(Date.UTC(match[1], match[2] - 1, match[3], match[4], match[5], match[6]));

            offlineForNewSubmissions = offlineForNewSubmissions !== undefined ? offlineForNewSubmissions : offline;
            match = offlineForNewSubmissions.match(/^(\d+)-(\d+)-(\d+) (\d+):(\d+):(\d+)$/);
            var dateOfflineForNewSubmissions = new Date(Date.UTC(match[1], match[2] - 1, match[3], match[4], match[5], match[6]));

            // Calculate if stemtool is active
            let stemtoolIsActive = (( now - dateOnline ) > 0 ) && ((dateOffline - now) > 0 ) ? true : false;
            if (!stemtoolIsActive) {
                let closeStep = null;
                // Find the step that has the closed type
                steps.all.forEach((step) => {
                    if(step.type === 'closed')
                        closeStep = step;
                });
                this.props.history.push('/' + slug + '/' + closeStep.stepNo );
                return;
            }

            // Calculate is stemtool is no longer active for new submissions and we're before the first save
            let stemtoolIsActiveForNewSubmissions = ((dateOfflineForNewSubmissions - now) > 0) ? true : false;
            if (!stemtoolIsActiveForNewSubmissions) {
                // Are we before the first save?
                var firstSavingStep = null;
                steps.all.forEach((step) => {
                    if (step.saveonnext === true && firstSavingStep === null) {
                        firstSavingStep = step;
                    }
                });

                if (currStep.stepNo <= firstSavingStep.stepNo) {
                    // Find the step that has the closed type
                    let closeStep = null;
                    steps.all.forEach((step) => {
                        if(step.type === 'closed')
                            closeStep = step;
                    });

                    this.props.history.push('/' + slug + '/' + closeStep.stepNo );
                    return;
                }
            }
        }

        /***********************************************************************
         *
         * This piece of code prevents non active pages to be shown
         *
         **********************************************************************/
        let {stemtool} = store.getState(); // !!! GET FROM STORE HERE !!! //

        let {formstates} = stemtool;

        // fiddle the current stepstate out of it all
        let {stepIndex} = props.steps.currStep;

        // If the step is not enabled OR the step is saved go to the first enabled non saved step
        if (!formstates[stepIndex].enabled || formstates[stepIndex].saved) {
            // Find the highest enabled step and go there
            for (var i = formstates.length - 1; i>=0; i--) {
                if (formstates[i].enabled && !formstates[i].saved) {
                    break;
                }
            }

            // Get the stepNo
            let stepNo = props.steps.all[i].stepNo;

            // And jump to that page
            props.history.push('/' + slug + '/'+ stepNo);
        }

        Analytics.count(props.stemtool.channel_slug, props.stemtool.slug, currStep.stepNo + '-' + currStep.type);

    }
}

export default Page;


