import React, { Component } from 'react';
import { connect } from 'react-redux';
import VisibilitySensor  from 'react-visibility-sensor';
import Song  from './Song';
import Player  from './Player';
import SongFreechoice from './SongFreechoice';

import { getLonglist, setSelected, unsetSelected } from  '../redux/actions/LonglistAction';
import { removeSongFromShortlistBySong } from  '../redux/actions/ShortlistAction';
import { toggleHighlight } from  '../redux/actions/StemtoolAction';

import ModelShortlist from '../model/ModelShortlist';

class Longlist extends Component {

    // Need these to calclulate the lazy loading
    limit = null;
    treshold = null;

    _player = null; // ref for the player

    // Load initial data (when we do not have it)
    componentWillMount (){

        let {settings} = this.props.longlist;
        this.limit = settings.limit;
        this.treshold = settings.treshold;
        this.props.getLonglist( this.props.slug, 0 );
    }

    // Should add the a longlist item to the shortlist
    onSongAdd ( index ) {
        ModelShortlist.addToShortlist( index, this );
    }

    onSongDelete ( index ) {
        let {list} = this.props.longlist;
        // Unset in longlist
        this.props.unsetSelected(index);

        this.props.toggleHighlight();
        this.props.removeSongFromShortlistBySong( list[index], this.props.currStep );
    }


    onSongHighlight ( index ) {

        let {list} = this.props.longlist;
        this.props.toggleHighlight(list[index]);
    }
    /**
     * This one fires when a visibility detector becomes visible
     * It will request a new load (but when already loaded it wont do a thing)
     */
    onSongVisible = function (listIndex,isVisible) {
        if( isVisible ){
            this.props.getLonglist( this.props.slug, listIndex );
        }
    }
    // Returns jsx (all Song's in an array)
    getList = (list) => {
        return list.map((song, i) => {
           return this.getSong(song,i);
         });
    }
    // Visibility sensor is there for the lazy loading..
    // When it becomes visible another load is requested
    getSong(song,i){

        // calculate the position of the visibility detector
        // add on both the treshold-position AND at the end of the chunk
        let addVisibilitySensor = ( i === (Math.ceil( i / this.limit ) * this.limit) - 1 || i === (Math.ceil( i / this.limit ) * this.limit) - this.treshold ) ? true : false;

        var rSong = <Song
            index={i}
            song={song}
            onAdd={this.onSongAdd.bind(this)}
            onDelete={this.onSongDelete.bind(this)}
            onSongClick={this.onSongHighlight.bind(this)}
            currStep={this.props.currStep}
            parent={this}
            mobile={this.props.mobile}
            type="add"
        />
        return (
            <li key={i}>
                { !addVisibilitySensor ?
                    rSong :
                    <VisibilitySensor onChange={this.onSongVisible.bind(this,i)} >{rSong}</VisibilitySensor>
                }
            </li>
        );
    }


    render() {
        let {list} = this.props.longlist;
        let {currStep, page, translate} = this.props;

        if(list.length > 0){
            return (

                    <ul className='list--longlist list__none'>
                        <div style={{ flex: '1 1 auto', position: 'relative' }}>

                            <Player ref={ (player) => {this._player = player}} />

                            <div>
                                { this.getList(list) }
                                {currStep.freeChoiceButton === 'in_longlist' ?
                                <SongFreechoice currStep={currStep} page={page}/> : null}
                            </div>

                        </div>
                    </ul>

            );
        } else {
            // Have add to freechoice at them bottom
            // Youre here with empty longlist
            if(currStep.freeChoiceButton === 'in_longlist' && currStep.max_free_choice > 0) {
                return (
                    <ul className='list--longlist list__none'>
                        <div style={{flex: '1 1 auto', position: 'relative'}}>

                            <div>
                                <SongFreechoice currStep={currStep} page={page}/>
                            </div>

                        </div>
                    </ul>

                )
            } else {
                return (
                    <div>
                        <h3 className="color--lightgray">{translate('Geen resultaten gevonden...')}</h3>
                    </div>
                );
            }
        }
    }
}

const mapStateToProps = (state) => {
    return {
        longlist: state.longlist,
        shortlist: state.shortlist
    }
}

export default connect( mapStateToProps, {
    getLonglist,
    setSelected,
    unsetSelected,
    removeSongFromShortlistBySong,
    toggleHighlight

})(Longlist);
