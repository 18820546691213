import React, {Component} from 'react';

import * as st from "../redux/actions/StemtoolAction";
import {store} from "../redux/store";

class PageLogout extends Component {

     componentDidMount(){

         // reset all
         setTimeout(function () {
             // Reset Redux
             st.resetApplication()(store.dispatch);
             // Get slug from URL
             let slug = document.location.pathname.split('/')[1];
             // and redirect
             document.location.href = '/' + slug + '/1';

         },2000)

     }

    render() {


        return (
            <div style={{paddingTop: "100px"}}>
                Je wordt uitgelogd...
            </div>
        );
    }

}


export default PageLogout;