import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleHighlight } from  '../redux/actions/StemtoolAction';
import ModelShortlist from '../model/ModelShortlist';
import YoutubePlayer from './YoutubePlayer';

class SongDetails extends Component {

    close = () => {
        this.props.toggleHighlight();
        document.body.classList.remove("SongDetails_Body--open");
    }

    add = () => {
        ModelShortlist.addToShortlist(null, this, this.props.highlight);
        document.body.classList.remove("SongDetails_Body--open");
    }

    render() {
        
        if(!this.props.highlight){
            document.body.classList.remove("SongDetails_Body--open");
            return null;
        }

        // Add body-class, so we can disable body-scrolling on mobile
        document.body.classList.add("SongDetails_Body--open");

        let song = this.props.highlight._source;

        let {mobile, translate} = this.props;
        
        let image = null;
        if(song.image) {
            image = song.imageBig;
        } else {
            image = null;
        }

        var body = (<div dangerouslySetInnerHTML={{__html: (song.bodyOverrule ? song.bodyOverrule : song.body) }} />);

        // Do we show or show not the add button
        var showAddButt = true;
        for(var i in this.props.shortlist){
            if(this.props.shortlist[i]._id === this.props.highlight._id){
                showAddButt = false;
            }
        }

        return (
            <div className={`col col--100 clear article panel--gray padding`}>
                <div className="article-btn-top--container">
                    <button onClick={this.close} className='btn btn--left btn--previous btn--whiteblack'>
                        <span className="before"></span>
                         <div className="title--container">
                            <span className="button--title t--right text-transform--uppercase">
                                {mobile ? translate('Terug naar de stemlijst') : translate('Terug naar je favorietenlijst') }
                            </span>
                         </div>
                         <span className="after"></span>
                    </button>
                </div>

                <div className="detail--header">
                    {image && <img
                        className="detail--image"
                        style={{visibility: 'hidden'}}
                        src={image}
                        onLoad={(e) => { e.target.style.visibility='visible'; }}
                        alt='artiest' />
                    }

                    <div className='font-size--sm margin--bottom color--lightgray article'>
                        <div className="artist">{song.artist}</div>
                        <div className="title">{song.title}</div>
                    </div>
                </div>

                {song.youtubeId && <YoutubePlayer id={song.youtubeId} />}

                {body && <div className='font-size--sm margin--bottom color--lightgray article'>
                    <div className="body">{body}</div>
                </div>}

                {showAddButt && <div className="article-btn-bottom--container">
                    <button onClick={this.add} className='btn btn--left btn--whiteblack'>
                        <i className="icon icon__add--black"></i>
                        <span className="title--container">
                            <p className='color--black text-transform--uppercase'>{translate('Voeg toe')}</p>
                        </span>
                    </button>
                </div>}

                {!showAddButt && <p className='color--black font-weight--bold font-size'>
                        <span>{translate('Toegevoegd als favoriet')}</span>
                    </p>}

            </div> )
        }
    }

const mapStateToProps = (state) => {
    return {
        shortlist: state.shortlist.list
    }
}
export default connect( mapStateToProps, {  toggleHighlight })(SongDetails);
