import * as type from '../types/ShortlistType';

const defaultState = {
    list: []
};

export default function (state = defaultState, action) {
	let list = state.list.slice(0);

    switch (action.type) {
        case type.ADD_SONG_TO_SHORTLIST:
            // Add the song at the end of the list
            list.push(action.payload);

            return {
                ...state,
				list,
            }

        case type.ADD_MOTIVATIONS_TO_SHORTLIST:
            // And add the motivation
            list[action.payload.index]._motivation = action.payload.motivation;

            return {
                ...state,
                list: list
            }

        case type.TOGGLE_MOTIVATION_VISIBILITY:
            let song = list[action.payload];

            song._motivationVisible = !song._motivationVisible;
            return {
                ...state,
                list: list
            }

        case type.REMOVE_SONG_FROM_SHORTLIST_BY_ID:
            list.splice(action.payload, 1);

            // if none is open open first
            if(list.length){
                var test = false;
                for(var i in list){
                    if(list[i]._motivationVisible)
                        test = true;
                }
                if(!test){
                    list[0]._motivationVisible = true;
                }
            }
            return {
                ...state,
                list: list
            }

        case type.REMOVE_SONG_FROM_SHORTLIST_BY_SONG:
            // Have to find the index
            for(var index in list)
                if(list[index]._id === action.song._id)
                    break;
            // And remove
            list.splice(index, 1);
            return {
                ...state,
                list: list
            }

        case type.CLEAR_SHORTLIST:

            return {
                ...state,
                list: []
            }

        default:
            return state;

    }
}


